import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import Button from "../Button/";

const Footer: React.FC = ({alumne,setStep,onClickNext, step}: any) => {
    const history = useHistory();
    useEffect(() => {
        if(alumne.step > 0 && alumne.step < 5){
            if(history.location.pathname !== '/pas'+alumne.step){
                history.push('/pas'+alumne.step)
            }
        }
    }, [alumne, step]);

    const handleAnterior = ()=>{
        setStep(alumne.idMatricules,parseInt(alumne.step) - 1)
    }

    const handleSeguent = ()=>{
        if(onClickNext){
            onClickNext();
        }
        if(!onClickNext){
            setStep(alumne.idMatricules,parseInt(alumne.step) + 1)
        }
    }


    return (
        <>
            <div className="flex flex-row align-center justify-between w-full mb-10 p-4">
                {
                    alumne.step > 1 &&
                    <Button onClick={e => {handleAnterior();}}
                            title={"Tornar pas " + (parseInt(alumne.step) - 1)}
                            className="bg-black"
                    >
                    </Button>
                }

                {
                    alumne.step > 1 && alumne.step < 4 &&
                    <Button onClick={e => {handleSeguent();}}
                            title={"Guardar i seguir pas " + (parseInt(alumne.step) + 1)}
                            className="ml-auto"
                    >
                    </Button>
                }

            </div>
            <div className="flex flex-col mt-auto items-center justify-center bg-gray-100 w-full p-4">
                <p className="text-black text-xs">
                    ESCOLA LLIURE EL SOL · Av. de les Drassanes, 3 · 08001 Barcelona · T: 93 481 73 83 · elsol@escolaelsol.coop <br/>
                    Reconeguda per la Generalitat de Catalunya com a escola de formació d’educadors/es en el temps lliure infantil i juvenil</p>
            </div>
        </>
    )

}

export default withTranslation('common')(Footer);
