import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import Login from "./pages/login";
import CreateNewUser from "./pages/createNewUser";
import Step1 from "./pages/step1";
import PrivateRoute from "./components/PrivateRoute";
import Step2 from "./pages/step2";
import Step3 from "./pages/step3";
import Step4 from "./pages/step4";
import ForgotPassword from "./pages/forgotPassword";

const Routes = ({}:any) => {

    useEffect(() => {


    }, []);


    return(
        <Router>
                <Switch>
                    <Route path="/" exact={true}>
                        <Login/>
                    </Route>
                    <Route path="/createnewuser" exact={true}>
                        <CreateNewUser/>
                    </Route>
                    <Route path="/forgotPassword" exact={true}>
                        <ForgotPassword/>
                    </Route>
                    <PrivateRoute path="/pas1">
                        <Step1/>
                    </PrivateRoute>
                    <PrivateRoute path="/pas2">
                        <Step2/>
                    </PrivateRoute>
                    <PrivateRoute path="/pas3">
                        <Step3/>
                    </PrivateRoute>
                    <PrivateRoute path="/pas4">
                        <Step4 />
                    </PrivateRoute>
                </Switch>
        </Router>
    );
}

const mapStateToProps = createStructuredSelector<any,any>({

});

const mapDispatchToProps = {

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Routes);
