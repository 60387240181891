import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Input.Component';
import {AppState} from "../../redux/store/store";
import React from "react";

interface InputProps{
    title : string;
    name?: string;
    value?: string | number | boolean;
    disabled?: boolean;
    required?: boolean;
    type?: string;
    placeholder?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const mapStateToProps = (state:AppState, props:InputProps) => {
    return createStructuredSelector<any,any>({
    });
};

const mapDispatchToProps = {
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
