import React, {FormEvent, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import Input from "../Input";
import Button from "../Button";
import ButtonRemoveFile from "../ButtonRemoveFile";

const FormPas4: React.FC = ({
                                alumne,
                                removeFile,
                                addMemoria,
                                addProjecte,
                                getMemoria,
                                getProjecte,
                                memoria,
                                projecte,
                                sendConvocatoria,
                                statusDocument
                            }: any) => {

    const [documents, setDocuments] = useState({
        m_memoria_file: "",
        m_memoria_file_url: "",
        m_memoria_file_name: "",
        m_projecte_file: "",
        m_projecte_file_url: "",
        m_projecte_file_name: "",
    })

    useEffect(() => {
        if (alumne.m_projecte_files !== null && (alumne.m_projecte_files || alumne.m_projecte_files.length > 0)) {
            getProjecte(parseInt(alumne.m_projecte_files), 'projecte')
        }
        if (alumne.m_memoria_files !== null && (alumne.m_memoria_files || alumne.m_memoria_files.length > 0)) {
            getMemoria(parseInt(alumne.m_memoria_files), 'memoria')
        }
    }, [alumne]);

    useEffect(() => {
        setDocuments((prevState) => ({
            ...prevState,
            ['m_memoria_file']: memoria.file,
            ['m_memoria_file_url']: memoria.file_url,
            ['m_memoria_file_name']: memoria.file_name,
            ['m_projecte_file']: projecte.file,
            ['m_projecte_file_url']: projecte.file_url,
            ['m_projecte_file_name']: projecte.file_name,
        }))
    }, [memoria, projecte])

    useEffect(()=>{
        if(statusDocument === 'failed'){
            setDocuments((prevState) => ({
                ...prevState,
                ['m_memoria_file']: "",
                ['m_memoria_file_url']: '',
                ['m_memoria_file_name']: '',
                ['m_projecte_file']: "",
                ['m_projecte_file_url']: '',
                ['m_projecte_file_name']: '',
            }))
        }

    },[statusDocument])

    const handleRemoveFile = (type: string) => {
        if (type === 'memoria') {
            removeFile(memoria.file, alumne.idMatricules, type);
            setDocuments((prevState) => ({
                ...prevState,
                ['m_memoria_file']: "",
                ['m_memoria_file_url']: '',
                ['m_memoria_file_name']: '',
            }))
        }
        if (type === 'projecte') {
            removeFile(projecte.file, alumne.idMatricules, type);
            setDocuments((prevState) => ({
                ...prevState,
                ['m_projecte_file']: "",
                ['m_projecte_file_url']: '',
                ['m_projecte_file_name']: '',
            }))
        }
    }



    const handleMemoriaProjecte = (e: React.ChangeEvent<HTMLInputElement>, type: string): void => {
        let files = e.target.files;
        let fileReader = new FileReader();
        if (files) {
            fileReader.readAsDataURL(files[0]);
            fileReader.onload = (event) => {
                if (fileReader.result) {
                    if (type === 'memoria') {
                        addMemoria({
                            file_name: files![0].name,
                            file: fileReader.result,
                        }, alumne.idMatricules);
                    }
                    if (type === 'projecte') {
                        addProjecte({
                            file_name: files![0].name,
                            file: fileReader.result,
                        }, alumne.idMatricules);
                    }
                    e.target.value = ""
                }
            }
        }

    }


    return (
        <>
            <div className="flex flex-col px-8 py-6 mt-4 text-left bg-white w-4/5 	">
                <div className="border-solid border-t-2 p-4 border-orange-600 shadow-lg">
                    {
                        (alumne.m_data_entrega && alumne.m_data_entrega === '0000-00-00') &&
                        <>
                            <p className="text-center text-justify text-lg mb-4">
                                Si ja has finalitzat les pràctiques penja aquí la memòria de pràctiques.
                                I si estàs fent el <b>curs de Director/a</b> també el projecte.
                            </p>
                            <p className="text-center text-justify text-lg mb-4">
                                Penja el document en format PDF,
                            </p>
                            <p className="text-center text-justify text-lg mb-4">
                                Recorda que en el moment de penjar la memòria has d’haver entregat el certificat
                                de pràctiques per tal que puguem certificar que l’etapa pràctica està superada!
                            </p>
                            <p className="text-center text-justify text-lg mb-4">
                                En cas de no fer-ho, pots quedar fora de la convocatòria.
                                Gràcies!
                            </p>
                        </>
                    }

                    <form className={"flex flex-col justify-content-center"}>
                        {(!documents.m_memoria_file || documents.m_memoria_file.length === 0) &&

                        <Input title="Document de memòria de pràctiques:"
                               type={'file'}
                               onChange={e => {
                                   handleMemoriaProjecte(e, 'memoria');
                               }}
                               
                        />
                        }
                        {
                            process.env.REACT_APP_HELIOS_URL &&
                            (documents.m_memoria_file && documents.m_memoria_file.length > 0)
                            &&
                            <>
                                <div className="w-full ">
                                    <div className=" md:grid md:grid-cols-6 md:items-center mb-6">
                                        <div className="col-span-2">
                                            <label
                                                className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                Document de memòria de pràctiques*:
                                            </label>
                                        </div>
                                        <div className="flex col-span-3 bg-gray-200 ">
                                            <p className="p-1 w-full flex flex-row space-between justify-center">
                                                <a className="text-orange-400"
                                                   href={process.env.REACT_APP_HELIOS_URL + 'ET/'
                                                   + documents.m_memoria_file_url
                                                   + documents.m_memoria_file}
                                                   target="_blank">
                                                    {
                                                        documents.m_memoria_file_name
                                                    }
                                                </a>
                                                {
                                                    (!alumne.m_memoria_files || alumne.m_qualificacio === 'Pendent de correccions') &&
                                                    <>
                                                        <ButtonRemoveFile onClick={e => {
                                                            e.preventDefault();
                                                            handleRemoveFile('memoria')
                                                        }}/>
                                                    </>
                                                }

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {alumne.tipus_curs === 'Director' &&
                        <>
                            {
                                (!documents.m_projecte_file || documents.m_projecte_file.length === 0) &&
                                <Input title="Document de projecte de pràctiques de director/a:&nbsp;"
                                       type={'file'}
                                       onChange={e => {
                                           handleMemoriaProjecte(e, 'projecte')
                                       }}
                                />

                            }
                            {
                                process.env.REACT_APP_HELIOS_URL && documents.m_projecte_file &&
                                <>
                                    <div className="w-full ">
                                        <div className=" md:grid md:grid-cols-6 md:items-center mb-6">
                                            <div className="col-span-2">
                                                <label
                                                    className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                    Document de projecte de pràctiques de director/a*:&nbsp;
                                                </label>
                                            </div>
                                            <div className="flex col-span-3 bg-gray-200 ">
                                                <p className="p-1 w-full flex flex-row space-between justify-center">
                                                    <a className="text-orange-400"
                                                       href={process.env.REACT_APP_HELIOS_URL + 'ET/'
                                                       + documents.m_projecte_file_url
                                                       + documents.m_projecte_file}
                                                       target="_blank">
                                                        {
                                                            documents.m_projecte_file_name
                                                        }
                                                    </a>
                                                    {
                                                        (!alumne.m_memoria_files || alumne.m_qualificacio_projecte === 'Pendent de correccions') &&
                                                        <>
                                                            <ButtonRemoveFile onClick={e => {
                                                                e.preventDefault();
                                                                handleRemoveFile('projecte')
                                                            }}/>
                                                        </>
                                                    }

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                        }
                        {
                            (!alumne.m_memoria_files || alumne.m_memoria_files === 'Pendent de correccions') &&
                            <>
                                <Button title={"Enviar les meves dades"} className={"w-1/3 mx-auto"}/>
                            </>
                        }
                    </form>
                </div>
            </div>

        </>
    )

}

export default withTranslation('common')(FormPas4);
