import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SubHeader from "src/components/SubHeader";
import moment from 'moment';
import FormPas4 from "../../components/FormPas4";
import EndConvocatoria from "../../components/EndConvocatoriaMessage";
import Pas4FinalMessage from "../../components/Pas4FinalMessage";
import Pas4QualificationMessage from "../../components/Pas4QualificationMessage";
import SpinnerComponent from "../../components/SpinnerComponent";
import Modal from "../../components/Modal";

const Step4: React.FC = ({
                             fileId,
                             retrieveAlumne,
                             alumne,
                             statusDocuments,
                             sendConvocatoria
                         }: any) => {

    //Check if a date is after another date
    const [isEndConvocatoria, setIsEndConvocatoria] = useState(true);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (alumne.convocatoria_tramitacio && alumne.convocatoria_tramitacio !== '0000-00-00') {
            const start = moment(alumne.max_convocatoria_data).isAfter(alumne.convocatoria_tramitacio);
            setIsEndConvocatoria(start);
        }

    }, [alumne]);

    useEffect(() => {
        retrieveAlumne();
    }, [fileId]);

    useEffect(() => {
        if(statusDocuments === 'loading'){
            setLoading(true)
        }
        if(statusDocuments === 'failed'){
            setMessage('Error al pujar el document, si us plau intentar pujar el document un altre vegada')
            setOpenModal(true);
        }
        if(statusDocuments === 'idle'){
            // setTimeout(() => {
            //     setLoading(false);
            // }, 3000);
            setLoading(false)
        }
        if(statusDocuments === 'completed-projecte'){
            if(alumne.m_memoria_files && alumne.m_memoria_files.length > 0){
                setOpenModal(true);
                setMessage(generarMensajeConfirmacion())
                sendConvocatoria(alumne.idMatricules);
            }
        }
        if(statusDocuments === 'completed-memoria'){
            if((alumne.m_projecte_files && alumne.m_projecte_files.length > 0 )|| alumne.tipus_curs === 'Monitor'){
                setOpenModal(true); 
                setMessage(generarMensajeConfirmacion())
                sendConvocatoria(alumne.idMatricules);
            }
        }
    }, [statusDocuments]);

    const generarMensajeConfirmacion = () => {

        const fechaInicioMoment = moment(new Date(), 'D MMMM');
        const entrance =  alumne.tipus_curs === 'Director' ?
            'Enhorabona, la teva memòria i projecte s\'han penjat correctament' :
            'Enhorabona, la teva memòria ha estat penjada correctament';
        if (fechaInicioMoment.isBetween(moment('16 October', 'D MMMM'), moment('15 January', 'D MMMM'), 'day', '[]')) {
            return `${entrance}. Has entrat a la Convocatòria del 15 de Gener i si l'avaluació és APTA el teu títol es tramitarà l'1 de Març. Et mantindrem informat/da`;
        } else if (fechaInicioMoment.isBetween(moment('16 January', 'D MMMM'), moment('15 April', 'D MMMM'), 'day', '[]')) {
            return `${entrance}. Has entrat a la Convocatòria del 15 d'Abril i si l'avaluació és APTA el teu títol es tramitarà l'1 de Juny. Et mantindrem informat/da`;
        } else if (fechaInicioMoment.isBetween(moment('16 April', 'D MMMM'), moment('15 October', 'D MMMM'), 'day', '[]')) {
            return `${entrance}. Has entrat a la Convocatòria del 15 d'Octubre i si l'avaluació és APTA el teu títol es tramitarà l'1 de Desembre. Et mantindrem informat/da`;
        } else {
            return 'No es pot generar el missatge de confirmació per aquestes dates.';
        }
    }

    return (
        <>
            <div className="flex flex-col items-center justify-between min-h-screen bg-white">
                <Header/>
                <SubHeader title="4. LLIUREMENT DE LA MEMÒRIA DE PRÀCTIQUES"/>
                <p className="w-4/5 px-8">
                    La memòria de pràctiques és el document que recull la teva experiència
                    durant l'etapa de pràctiques. Recorda que pots trobar
                    el guió de la memòria a l’apartat de pràctiques de l'aula virtual .
                    Si tens dubtes parla amb la teva tutora, ella és qui t'orientarà i posteriorment la corregirà.

                </p>
                {/* Si convocatoria > max-convocatoria */}
                <EndConvocatoria endConvocatoria={isEndConvocatoria}/>
                <Pas4FinalMessage endConvocatoria={isEndConvocatoria} alumne={alumne}/>
                <Pas4QualificationMessage endConvocatoria={isEndConvocatoria} alumne={alumne}/>
                <FormPas4/>
                <Footer/>
                <SpinnerComponent open={loading} />
                <Modal open={openModal} setOpen={setOpenModal}>
                    <p className="mx-auto">
                        {message}
                    </p>
                </Modal>
            </div>
        </>
    )
}

export default withTranslation('common')(Step4);
