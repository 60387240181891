import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './LoginForm.Component';
import {
    getIsInProgress,
    getUser,
    retrieveUser,
    validateToken
} from "../../redux/reducers/userReducer";


const mapStateToProps = createStructuredSelector<any,any>({
    user:getUser,
    isInProgress: getIsInProgress
});

const mapDispatchToProps = {
    retrieveUser: retrieveUser,
    validateUser: validateToken
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
