import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import Component from './CreateNewPasswordForm.Component';
import {createUser, getError, getIsCompleted, getIsInProgress} from "../../redux/reducers/userReducer";


const mapStateToProps = createStructuredSelector<any,any>({
    isInProgress: getIsInProgress,
    completed: getIsCompleted,
    error: getError
});

const mapDispatchToProps = {
    createUser: createUser,
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
