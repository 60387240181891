import React from "react";

class ButtonRemoveFile extends React.Component<{ onClick: (e: any) => void }, any> {
    render() {
        return <button
            className="cursor-pointer
                                relative
                                ml-auto
                                text-gray-400
                                hover:text-gray-600
                                transition
                                duration-150
                                ease-in-out
                                rounded
                                focus:ring-2
                                focus:outline-none
                                focus:ring-gray-600"
            onClick={this.props.onClick}
            aria-label="close modal" role="button">
            <svg xmlns="http://www.w3.org/2000/svg"
                 className="icon icon-tabler icon-tabler-x"
                 width="20" height="20" viewBox="0 0 24 24"
                 strokeWidth="2.5"
                 stroke="currentColor" fill="none"
                 strokeLinecap="round"
                 strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"/>
                <line x1="18" y1="6" x2="6" y2="18"/>
                <line x1="6" y1="6" x2="18" y2="18"/>
            </svg>
        </button>;
    }
}

export default ButtonRemoveFile;