import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './CreateNewUser.Component';


const mapStateToProps = createStructuredSelector<any,any>({
});

const mapDispatchToProps = {
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
