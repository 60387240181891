import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";

const Modal: React.FC = ({children,open, setOpen, closeButton = true, onClose}: any) => {
    const [openModal, setOpenModal] = useState(open)
    useEffect(() =>{
        setOpenModal(open);
    },[open])

    return (
        <>
            {
                openModal &&
                <>
                    <div
                        className="py-12 bg-gray-100 transition duration-150 ease-in-out z-10 fixed
                                    top-0 right-0 bottom-0 left-0 bg-opacity-80 flex align-items-center	"
                        id="modal">
                        <div role="alert" className="container mx-auto my-auto w-2/4 max-h-modal overflow-auto">
                            <div
                                className="flex flex-col justify-content-center relative py-8 px-5
                                md:px-10 bg-white shadow-md rounded
                                 border border-gray-400 h-5/6 overflow-y-auto">
                                {
                                    closeButton &&
                                    <button
                                        className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                                        onClick={e => {
                                            if(onClose){
                                                onClose();
                                            }
                                            setOpenModal(!openModal);
                                            setOpen(!openModal);
                                        }}
                                        aria-label="close modal" role="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x"
                                             width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5"
                                             stroke="currentColor" fill="none" strokeLinecap="round"
                                             strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z"/>
                                            <line x1="18" y1="6" x2="6" y2="18"/>
                                            <line x1="6" y1="6" x2="18" y2="18"/>
                                        </svg>
                                    </button>
                                }

                                {children}
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )

}

export default withTranslation('common')(Modal);
