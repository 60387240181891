import { createSelector } from 'reselect'
import {AppState} from "../store/store";
import {Dispatch} from "redux";
import {Provincia} from "../../types/provincies";
import {getAllProvincies} from "../../services/provincies";

export const ACTIONS = {
    QUERY_PROVINCIES_REQUEST: 'QUERY_PROVINCIES_REQUEST',
    QUERY_PROVINCIES_SUCCESS: 'QUERY_PROVINCIES_SUCCESS',
    QUERY_PROVINCIES_FAILURE: 'QUERY_PROVINCIES_FAILURE',
};

const InitialState = {
    provincies : [] as Provincia[],
    inProgress : false,
    error: ''
}

const getSlice = (state: AppState) => state.provincies;

export const getProvincies = createSelector(
    getSlice,
    ({provincies}) => provincies,
);

export const setInProgress = (): ProvinciesActions => ({
    type :ACTIONS.QUERY_PROVINCIES_REQUEST,
})

export const setProvinciesSuccess = (provincies: Provincia[]): ProvinciesActions => ({
    type:ACTIONS.QUERY_PROVINCIES_SUCCESS,
    provincies
})

export const setProvinciesFailure = (error:string): ProvinciesActions => ({
    type :ACTIONS.QUERY_PROVINCIES_FAILURE,
    error
})


export interface ProvinciesRequestAction {
    type: typeof ACTIONS.QUERY_PROVINCIES_REQUEST
}

export interface ProvinciesSuccessAction {
    type: typeof ACTIONS.QUERY_PROVINCIES_SUCCESS,
    provincies : Provincia[]
}

export interface ProvinciesFailureAction {
    type: typeof ACTIONS.QUERY_PROVINCIES_FAILURE,
    error : string
}


export type ProvinciesActions =
    ProvinciesRequestAction
    | ProvinciesSuccessAction
    | ProvinciesFailureAction
    ;

export const retrieveProvincies = () => {
    return async (dispatch: Dispatch<ProvinciesActions>) => {
        dispatch(setInProgress())
        try {
            const response = await getAllProvincies();
            if(response && response.data){
                dispatch(setProvinciesSuccess(response.data));
            }
        } catch (error:any) {
            dispatch(setProvinciesFailure(error))
        }
    }
}


const ProvinciaReducer = (state = InitialState, action: ProvinciesActions):
    { provincies: Provincia[]; inProgress: boolean; error: string } => {
    switch (action.type) {
        case ACTIONS.QUERY_PROVINCIES_REQUEST:
            return {
                ...state,
                inProgress : true,
                error: ''
            };
        case ACTIONS.QUERY_PROVINCIES_SUCCESS:
            return {
                ...state,
                inProgress : false,
                provincies: "provincies" in action ? action.provincies : []
            };
        case ACTIONS.QUERY_PROVINCIES_FAILURE:
            return {
                ...state,
                inProgress : false,
                error: "error" in action ? action.error : ''
            };
        default:
            return state;
    }
};



export {ProvinciaReducer};