import { combineReducers } from "redux";
import {UserReducer} from "./userReducer";
import {EntitatReducer} from "./entitatsReducer";
import {AlumneReducer} from "./alumneReducer";
import {ProvinciaReducer} from "./provinciesReducer";
import {TutorPractiquesReducer} from "./tutorPractiquesReducer";
import {documentReducer} from "./documentReducer";

const rootReducer = combineReducers({
    user: UserReducer,
    entities: EntitatReducer,
    alumne: AlumneReducer,
    provincies: ProvinciaReducer,
    tutorPractiques: TutorPractiquesReducer,
    documentsPractiques: documentReducer,
});

export default rootReducer;