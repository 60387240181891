import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Spinner.Component';
import {AppState} from "../../redux/store/store";
type Props = {
  open: boolean;
};

const mapStateToProps = (state: AppState, props: Props) => {
  return createStructuredSelector<any, any>({});
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
