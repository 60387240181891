import {api} from "../services/api";
import {Entity} from "../types/entity";

export async function getAllEntities() {
    if(localStorage.getItem('user')){
        const userData = JSON.parse(localStorage.getItem('user') as string);
        return await api.post<Entity[]>("/get_all_entitats", userData);
    }
}

export async function addNewEntity(entity: Entity) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);
        data['rao_social'] = entity.rao_social;
        data['responsable_legal'] = entity.responsable_legal;
        data['email_responsable'] = entity.email_responsable;
        data['telephone'] = entity.telephone;
        data['cif'] = entity.cif;
        data['file'] = entity.file;
        data['file_name'] = entity.file_name;

        return await api.post<any>("/add_centre", data);
    }
}


export async function changeEntity(entity: Entity) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);
        data['responsable_legal'] = entity.responsable_legal;
        data['email_responsable'] = entity.email_responsable;
        data['id'] = entity.id;

        return await api.post<any>("/update_centre", data);
    }
}


export async function getEntityById(idCentre: number) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);
        data['idCentre'] = idCentre;

        return await api.post<Entity>("/get_centre_by_id", data);
    }
}
