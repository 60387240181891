import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";

import logo from '../../assets/img/escola-el-sol.png'
import {useHistory} from "react-router-dom";

const Header: React.FC = ({title,alumne,clearAlumne,clearUser,subTitle}: any) => {

    const history = useHistory();

    const handelClose = ()=>{
        localStorage.removeItem("user");
        clearUser();
        clearAlumne();
        history.push("/")
    }
    return (
        <>
            <div className="flex flex-col items-center justify-center w-full">
                <div className="flex justify-between w-full bg-orange-400">
                    <div className="w-1/3" >

                    </div>
                    <div className="w-1/3 p-4 flex self-center items-center justify-center 	" >
                        <img className="p-4 w-2/4" src={logo}/>
                    </div>
                    <div className="w-1/3 flex flex-col  p-4		" >
                        { alumne.nom &&
                            <>
                                <h5 className="ml-auto text-white" >
                                    Benvolgut/da {alumne.nom} {alumne.cognoms}
                                </h5>
                               
                            </>
                        }
                         <button className="text-white align-right ml-auto hover:text-black"
                                        onClick={e => handelClose()}>
                                    Sortir
                                </button>
                    </div>
                </div>

                {
                    title &&
                    <h1 className="text-black text-3xl">
                        {title}
                    </h1>
                }
                {
                    subTitle &&
                    <h1 className="text-black text-3xl">
                        {subTitle}
                    </h1>
                }

            </div>
        </>
    )

}

export default withTranslation('common')(Header);
