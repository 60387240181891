import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import Component from './Step4.Component';
import {getAlumne, retrieveAlumne, sendConvocatoria} from "../../redux/reducers/alumneReducer";
import {getFileId, getStatusDocuments} from "../../redux/reducers/documentReducer";


const mapStateToProps = createStructuredSelector<any,any>({
    alumne: getAlumne,
    fileId:getFileId,
    statusDocuments: getStatusDocuments,
});

const mapDispatchToProps = {
    retrieveAlumne: retrieveAlumne,
    sendConvocatoria:sendConvocatoria
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
