import {api} from "../services/api";
import {FileGeneric} from "../types/alumne";

export async function getFileById(fileId:number) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);
        data['fileId'] = fileId;
        return await api.post<FileGeneric>("/get_file", data);
    }
}
