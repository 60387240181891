import React from "react";
import {withTranslation} from "react-i18next";

const Input: React.FC = ({title,value, disabled = false,type = 'text',name='' , placeholder='',onChange, required }: any) => {
    return (
        <>
            <div className="w-full ">
                <div className=" md:grid md:grid-cols-6 md:items-center mb-6">
                    <div className="col-span-2">
                        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                               htmlFor={name}>
                            {title}
                        </label>
                    </div>
                    {type === 'checkbox' &&
                    <>
                        <div className="col-span-3">
                            <input
                                className=""
                                placeholder={placeholder}
                                onChange={onChange}
                                type={type}
                                defaultValue={value}
                                checked={value ? true : false}
                                autoComplete="off"
                               />
                        </div>
                    </>
                    }
                    {type !== 'checkbox' &&
                    <>
                        <div className="flex flex-row col-span-3">
                            <input
                                className="bg-gray-200 appearance-none border-1 border-gray-200
                                rounded w-full py-2 px-4 text-gray-700 leading-tight
                                focus:ring-1 focus:ring-orange-600
                                focus:outline-none focus:bg-white focus:border-orange-500"
                                type={type}
                                defaultValue={value}
                                name={name}
                                placeholder={placeholder}
                                onChange={onChange}
                                disabled={disabled}
                                required={required}
                                autoComplete="off"
                            />
                        </div>
                    </>
                    }
                </div>
            </div>
        </>
    )

}

export default withTranslation('common')(Input);
