import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Header.Component';
import {AppState} from "../../redux/store/store";
import {clearUser,} from "../../redux/reducers/userReducer";
import {clearAlumne, getAlumne} from "../../redux/reducers/alumneReducer";


type HeaderProps = {
    title?: string;
    subTitle?:string;
}

const mapStateToProps = (state:AppState, props:HeaderProps) => {
    return createStructuredSelector<any,any>({
        alumne: getAlumne
    });
};

const mapDispatchToProps = {
    clearUser: clearUser,
    clearAlumne: clearAlumne
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);