import {api} from "../services/api";
import {DniObject, User, UserObject} from "../types/user";

export async function authenticactionUser(userData: UserObject) {
    const response = await api.post<User>("/create_token", userData)
    return response;
}

export async function validateTokenUser(userData: UserObject) {
    const response = await api.post<User>("/validate_user", userData)
    return response;
}

export async function newPassword(userData: UserObject) {
    const response = await api.post<User>("/new_password", userData)
    return response;
}

export async function create_new_user(userData: DniObject) {
    const response = await api.post("/add_new_user", userData)
    return response;
}
