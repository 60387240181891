import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {DniObject, User} from "../../types/user";
import {useHistory} from "react-router-dom";
import Modal from "../Modal";

const CreateNewPasswordForm: React.FC = ({user,isInProgress,createUser, error, completed}: any) => {

    const [userLogin,setUserLogin] = useState<DniObject>({
        dni:'',
    })
    const history = useHistory();
    const [openModalError, setOpenModalError] = useState(false)
    const [openModalOK, setOpenModalOK] = useState(false)


    useEffect(() => {
        if(!isInProgress && completed && !error){
            setOpenModalOK(true);
        }
        if(error){
            setOpenModalError(true);
        }
    }, [ isInProgress, completed, error]);

    const handleLogin = (e: React.FormEvent<HTMLFormElement>):void =>{
        e.preventDefault();
        createUser(userLogin);
    }

    const userChange = (name:any,event: React.ChangeEvent<HTMLInputElement>):void =>{
        const value  = event.target.value;

        setUserLogin((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    };


    return (
        <>
            <form
                onSubmit={(e)=>{handleLogin(e)}}>
                <div className="mt-4 ">
                    <div>
                        <label className="block" htmlFor="email">DNI</label>
                        <input type="text" placeholder="DNI/PASSAPORT"
                               className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none
                                            focus:ring-orange-600"
                               onChange={(e) => {
                                   userChange('dni',e);
                               }}
                        />
                    </div>
                    <div className="flex items-baseline justify-between">
                        <button
                            className="px-6 py-2 mt-4 text-white bg-orange-500 rounded-lg hover:bg-orange-900">
                            Crear Nou usuari
                        </button>
                    </div>
                </div>
            </form>
            {
                openModalError &&
                <>
                    <Modal open={openModalError} setOpen={setOpenModalError}>
                        <p>Hi ha un error amb la teu dni. O el teu usuari ja existe. Posat amb contacte amb la escoladelsol.</p>
                    </Modal>
                </>
            }
            {
                openModalOK &&
                <>
                    <Modal open={openModalOK} setOpen={(setOpenModalOK)}>
                        <p>Se ha creat el teu usuari</p>
                        <button
                            onClick={()=>{
                                history.push("/");
                            }}
                            className="px-6 py-2 mt-4 text-white bg-orange-500 rounded-lg hover:bg-orange-900">
                           Tornar
                        </button>
                    </Modal>
                </>
            }
        </>
    )

}

export default withTranslation('common')(CreateNewPasswordForm);
