import React from "react";
import {withTranslation} from "react-i18next";

const ButtonDownloadPdf: React.FC = ({title,nameDocument}: any) => {

    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        // using Java Script method to get PDF file
        fetch('documents/'+nameDocument).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = nameDocument;
                alink.click();
            })
        })
    }


    return (
        <>
            <button className="download" onClick={onButtonClick}>{title}</button>
        </>
    )

}

export default withTranslation('common')(ButtonDownloadPdf);
