import React from "react";

class EndConvocatoria extends React.Component<{ endConvocatoria: boolean }> {
    render() {
        return <>
            {
                !this.props.endConvocatoria &&
                <div className="flex flex-col px-8 py-6 mt-4 text-left bg-white w-4/5 	">
                    <div className="border-solid border-t-2 p-4 border-orange-600 shadow-lg">
                        <p>
                            Per a aquest curs ja s’han esgotat totes les convocatòries.
                            Et recomanem que contactis amb el teu tutor/a
                        </p>
                    </div>
                </div>
            }
        </>;
    }
}

export default EndConvocatoria;