import React from 'react';
import  './assets/styles/main.css';
import  './assets/styles/global.css';
import i18next from "i18next";
import {I18nextProvider} from "react-i18next";
import common_es from "./translations/es/common.json";
import common_ca from "./translations/ca/common.json";
import common_en from "./translations/en/common.json";
import Routes from "./routes";
require('dotenv').config();

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'es' ,                              // language to use
  resources: {
    es: {
      common: common_es               // 'common' is our custom namespace
    },
    ca: {
      common: common_ca               // 'common' is our custom namespace
    },
    en: {
      common: common_en               // 'common' is our custom namespace
    },
  },
});

function App() {
  return (
      <I18nextProvider i18n={i18next}>
        <Routes />
      </I18nextProvider>
  );
}

export default App;