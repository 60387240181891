import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Input from "../../components/Input";
import {useHistory} from "react-router-dom";
import Modal from "../../components/Modal";
import SubHeader from "../../components/SubHeader";
import ButtonDownloadPdf from "../../components/ButtonDownloadPdf";

const Step3: React.FC = ({retrieveAlumne, alumne,updateEndPractiques,setStep}: any) => {

    const [endPractiques, setEndPractiques] = useState(alumne.end_practiques);

    useEffect(() => {
        retrieveAlumne();
    }, []);

    useEffect(() => {
       setEndPractiques((alumne.end_practiques));
    }, [alumne]);

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) =>{
        const checkPractiques = e.target.checked ? 1 : 0;
        setEndPractiques(checkPractiques);
        updateEndPractiques(alumne.idMatricules,checkPractiques);
    }

    const [openModal, setOpenModal] = useState(false)
    const history = useHistory();
    const [message, setMessage] = useState('')

    const handleNextStep = ()=>{
        let pas4 = true;
        setMessage('')
        if(!endPractiques){
            pas4 = false;
            setMessage("Has de confirmar que has finalitzat el período de pràctiques");
            setOpenModal(true);
        }
        if(pas4){
            setStep(alumne.idMatricules,parseInt(alumne.step) + 1)
            history.push('/pas4')
        }

    }
    return (
        <>
            <div className="flex flex-col items-center justify-between min-h-screen bg-white">
               <Header />
                <SubHeader title="3. CERTIFICAT DE PRÀCTIQUES (AVALUACIÓ FINAL ETAPA PRÀCTIQUES)" />
                <div className="flex flex-col  w-full justify-center align-center self-center px-8 py-6 mt-4 text-left bg-white w-4/5	">
                    <div className="flex flex-col border-solid border-t-2 p-4 border-orange-600 shadow-lg w-full">
                        <p className="text-center text-lg mb-4">
                            Un cop finalitzis l'etapa de pràctiques, el teu tutor de pràctiques t'haurà de valorar i
                            qualificar omplint el document "Certificat de pràctiques".
                        </p>
                        <ul className="flex flex-col self-center px-8 py-2 text-left list-decimal w-10/12">
                            <li>Descarregar el document a continuació i entregar al director/a de pràctiques </li>
                            <li>Un cop finalitzades les pràctiques, el director/a de pràctiques ha d’omplir i signar
                            el document (amb signatura de certificat digital o a mà en bolígraf blau) </li>
                            <li>Fer arribar el certificat a l’Escola:
                                <ul className="flex flex-col self-center px-8 py-2 text-left list-disc w-10/12">
                                    <li>Signatura de certificat digital: enviar al correu practiques@escolaelsol.coop </li>
                                    <li>Signatura a mà: entregar presencialment o enviar per correu postal certificat. </li>
                                </ul>
                            </li>
                        </ul>
                        <p className="text-center text-xs  mb-4">
                            L'adreça de l'Escola Lliure el Sol és la següent:
                        </p>
                        <p className="text-center text-xs font-bold mb-4">
                            Escola Lliure el Sol<br />
                            Av. de les Drassanes, 3 <br/>
                            08001 Barcelona <br/>
                        </p>
                        <p className="text-center text-xs font-bold text-orange-400 mb-4">
                            {
                                alumne.tipus_curs === 'Director' &&
                                <ButtonDownloadPdf
                                    title={'DESCARREGA\'T EL CERTIFICAT DE PRÀCTIQUES'}
                                    nameDocument={'Certificat_Practiques_Director_2017.pdf'}
                                />
                            }
                            {
                                alumne.tipus_curs === 'Monitor' &&
                                <ButtonDownloadPdf
                                    title={'DESCARREGA\'T EL CERTIFICAT DE PRÀCTIQUES'}
                                    nameDocument={'Certificat_Practiques_Monitor_2017.pdf'}
                                />
                            }
                        </p>
                        <div  className="flex flex-col self-center py-2 text-left w-11/12">
                            <p className="text-center text-lg mb-4">
                                Per continuar i poder penjar la memòria de pràctiques has de validar que has acabat l'etapa de pràctiques:
                            </p>
                            <div className="flex flex-row text-center items-center self-center py-2 text-left">
                                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                                    Confirmo que he finalitzat el període de pràctiques:
                                </label>
                                <input
                                    className=""
                                    onChange={handleChange}
                                    type={'checkbox'}
                                    defaultValue={endPractiques}
                                    checked={endPractiques ? true : false}
                                />
                            </div>

                        </div>


                    </div>
                </div>
                <Modal open={openModal} setOpen={setOpenModal}>
                    <p className="mx-auto">
                        {message}
                    </p>
                </Modal>
                <Footer onClickNext={handleNextStep}/>
            </div>
        </>
    )

}

export default withTranslation('common')(Step3);
