import React from "react";
import {withTranslation} from "react-i18next";

const Button: React.FC = ({title,onClick,className}: any) => {

    return (
        <>
            <button
                className={"px-6 py-2 mt-4 text-white bg-orange-500 rounded-lg hover:bg-orange-900 " + className}
                onClick={onClick}>
                {title}
            </button>
        </>
    )

}

export default withTranslation('common')(Button);