import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Step1.Component';
import {clearUser, getUser, validateToken} from "../../redux/reducers/userReducer";
import {getEntities, getEntity, getError, getNewEntity, getStatusEntitats, retrieveEntities} from "../../redux/reducers/entitatsReducer";
import {clearAlumne, getAlumne, retrieveAlumne, updateIdCentre, updateStep} from "../../redux/reducers/alumneReducer";
import {retrieveProvincies} from "../../redux/reducers/provinciesReducer";


const mapStateToProps = createStructuredSelector<any,any>({
    user: getUser,
    entities: getEntities,
    newEntity: getNewEntity,
    status: getStatusEntitats,
    alumne: getAlumne,
    error: getError
});

const mapDispatchToProps = {
    retrieveUser : validateToken,
    retrieveEntities : retrieveEntities,
    retrieveAlumne: retrieveAlumne,
    retrieveProvincies: retrieveProvincies,
    setStep: updateStep,
    setCentre: updateIdCentre,
    getEntity: getEntity,
    clearUser: clearUser,
    clearAlumne: clearAlumne
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
