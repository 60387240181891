import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './FormPas4.Component';
import {getUser, validateToken} from "../../redux/reducers/userReducer";
import {getEntities, retrieveEntities} from "../../redux/reducers/entitatsReducer";
import {getAlumne, retrieveAlumne, sendConvocatoria} from "../../redux/reducers/alumneReducer";
import {
    addMemoria,
    addProjecte,
    getFile,
    getFileId,
    getMemoria,
    getProjecte, getStatusDocuments, removeFile
} from "../../redux/reducers/documentReducer";

const mapStateToProps = createStructuredSelector<any,any>({
    user: getUser,
    entities: getEntities,
    alumne: getAlumne,
    memoria: getMemoria,
    projecte: getProjecte,
    statusDocument: getStatusDocuments,
});

const mapDispatchToProps = {
    retrieveUser : validateToken,
    retrieveEntities : retrieveEntities,
    addMemoria: addMemoria,
    addProjecte: addProjecte,
    getMemoria: getFile,
    getProjecte: getFile,
    removeFile: removeFile,
    sendConvocatoria:sendConvocatoria
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
