import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './SubHeader.Component';
import {AppState} from "../../redux/store/store";

type SubHeaderProps = {
    title: string;
    description?: string;
}

const mapStateToProps = (state:AppState, props:SubHeaderProps) => {
    return createStructuredSelector<any,any>({
    });
};

const mapDispatchToProps = {
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);