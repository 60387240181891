import {createSelector} from 'reselect'
import {AppState} from "../store/store";
import {Dispatch} from "redux";
import {TutorPractiques} from "../../types/tutorPractiques";
import {
    addFileAcordPractiques,
    addFileTitolDirector,
    getTutorPractiquesByMatriculaId,
    removeFileAcordPractiques,
    removeFileTutorPractiques,
    updateTutorPractiques
} from "../../services/tutorPractiques";
import {FileAlumne, FileGeneric} from "../../types/alumne";
import {getFileById} from "../../services/file";

export const ACTIONS = {
    QUERY_TUTORPRACTIQUES_REQUEST: 'QUERY_TUTORPRACTIQUES_REQUEST',
    QUERY_TUTORPRACTIQUES_SUCCESS: 'QUERY_TUTORPRACTIQUES_SUCCESS',
    QUERY_TUTORPRACTIQUES_FAILURE: 'QUERY_TUTORPRACTIQUES_FAILURE',
    QUERY_ADD_TITOLTUTOR_REQUEST: 'QUERY_ADD_TITOLTUTOR_REQUEST',
    QUERY_ADD_TITOLTUTOR_SUCCESS: 'QUERY_ADD_TITOLTUTOR_SUCCESS',
    QUERY_ADD_TITOLTUTOR_FAILURE: 'QUERY_ADD_TITOLTUTOR_FAILURE',
    QUERY_GETTITOLTUTOR_REQUEST: 'QUERY_GETTITOLTUTOR_REQUEST',
    QUERY_GETTITOLTUTOR_SUCCESS: 'QUERY_GETTITOLTUTOR_SUCCESS',
    QUERY_GETTITOLTUTOR_FAILURE: 'QUERY_GETTITOLTUTOR_FAILURE',
    QUERY_ACORDPRACTIQUES_REQUEST: 'QUERY_ACORDPRACTIQUES_REQUEST',
    QUERY_ACORDPRACTIQUES_SUCCESS: 'QUERY_ACORDPRACTIQUES_SUCCESS',
    QUERY_ACORDPRACTIQUES_FAILED: 'QUERY_ACORDPRACTIQUES_FAILED',
    QUERY_GETACORDPRACTIQUES_REQUEST: 'QUERY_GETACORDPRACTIQUES_REQUEST',
    QUERY_GETACORDPRACTIQUES_SUCCESS: 'QUERY_GETACORDPRACTIQUES_SUCCESS',
    QUERY_GETACORDPRACTIQUES_FAILED: 'QUERY_GETACORDPRACTIQUES_FAILED',
    QUERY_REMOVEACORDPRACTIQUES_REQUEST: 'QUERY_REMOVEACORDPRACTIQUES_REQUEST',
    QUERY_REMOVEACORDPRACTIQUES_SUCCESS: 'QUERY_REMOVEACORDPRACTIQUES_SUCCESS',
    QUERY_REMOVEACORDPRACTIQUES_FAILED: 'QUERY_REMOVEACORDPRACTIQUES_FAILED',
    QUERY_REMOVETITOLTUTOR_REQUEST: 'QUERY_REMOVETITOLTUTOR_REQUEST',
    QUERY_REMOVETITOLTUTOR_SUCCESS: 'QUERY_REMOVETITOLTUTOR_SUCCESS',
    QUERY_REMOVETITOLTUTOR_FAILURE: 'QUERY_REMOVETITOLTUTOR_FAILURE',
    ENABLE_LOADER: 'ENABLE_LOADER',
    DISABLE_LOADER: 'DISABLE_LOADER',
};

const InitialState = {
    tutorPractiques: {} as TutorPractiques,
    status: 'idle', //idle||loading||completed||failed
    idFile: 0,
    fileGeneric: {} as FileGeneric,
    isRemove: false,
    error: '',
}

const getSlice = (state: AppState) => state.tutorPractiques;

export const getTutorPractiques = createSelector(
    getSlice,
    ({tutorPractiques}) => tutorPractiques,
);
export const getStatusTutorPractiques = createSelector(
    getSlice,
    ({status}) => status,
);

export const getErrorTutor = createSelector(
    getSlice,
    ({error}) => error,
);

export const setInProgress = (): TutorPractiquessActions => ({
    type: ACTIONS.QUERY_TUTORPRACTIQUES_REQUEST,
})

export const setTutorPractiquesSuccess = (tutorPractiques: TutorPractiques): TutorPractiquessActions => ({
    type: ACTIONS.QUERY_TUTORPRACTIQUES_SUCCESS,
    tutorPractiques
})

export const setTutorPractiquesFailure = (error: string): TutorPractiquessActions => ({
    type: ACTIONS.QUERY_TUTORPRACTIQUES_FAILURE,
    error
})

export const setFileTutorPractiquesSuccess = (idFile: number): TutorPractiquessActions => ({
    type: ACTIONS.QUERY_ADD_TITOLTUTOR_SUCCESS,
    idFile
})
export const setFileTutorPractiquesRequest = () => ({
    type: ACTIONS.QUERY_ADD_TITOLTUTOR_REQUEST,
})

export const setFileTutorPractiquesFailure = (error: string): TutorPractiquessActions => ({
    type: ACTIONS.QUERY_ADD_TITOLTUTOR_FAILURE,
    error
})


export const setFileAcordPractiquesSuccess = (idFile: number): TutorPractiquessActions => ({
    type: ACTIONS.QUERY_ACORDPRACTIQUES_SUCCESS,
    idFile
})

export const setFileAcordPractiquesRequest = (): TutorPractiquessActions => ({
    type: ACTIONS.QUERY_ACORDPRACTIQUES_REQUEST,
})

export const setFileAcordPractiquesFailure = (error: string): TutorPractiquessActions => ({
    type: ACTIONS.QUERY_ACORDPRACTIQUES_FAILED,
    error
})

export const getFileTutorPractiquesSuccess = (fileGeneric: FileGeneric): TutorPractiquessActions => ({
    type: ACTIONS.QUERY_GETTITOLTUTOR_SUCCESS,
    fileGeneric
})

export const getFileAcordPractiquesSuccess = (fileGeneric: FileGeneric): TutorPractiquessActions => ({
    type: ACTIONS.QUERY_GETACORDPRACTIQUES_SUCCESS,
    fileGeneric
})

export const removeFileTutorPractiquesSuccess = (isRemove: boolean): TutorPractiquessActions => ({
    type: ACTIONS.QUERY_REMOVETITOLTUTOR_SUCCESS,
    isRemove
})

export const removeFileAcordPractiquesSuccess = (isRemove: boolean): TutorPractiquessActions => ({
    type: ACTIONS.QUERY_REMOVEACORDPRACTIQUES_SUCCESS,
    isRemove
})

export const enableLoader = () => {
    return {
        type: ACTIONS.ENABLE_LOADER,
    };
}

export const disableLoader = () => {
    return {
        type: ACTIONS.DISABLE_LOADER,
    };
}

export interface TutorPractiquesRequestAction {
    type: typeof ACTIONS.QUERY_TUTORPRACTIQUES_REQUEST
}

export interface TutorPractiquesSuccessAction {
    type: typeof ACTIONS.QUERY_TUTORPRACTIQUES_SUCCESS,
    tutorPractiques: TutorPractiques
}

export interface TutorPractiquesFailureAction {
    type: typeof ACTIONS.QUERY_TUTORPRACTIQUES_FAILURE,
    error: string
}

export interface SetFileTutorPractiquesSuccessAction {
    type: typeof ACTIONS.QUERY_ADD_TITOLTUTOR_SUCCESS,
    idFile: number
}
export interface SetFileTutorPractiquesRequestAction {
    type: typeof ACTIONS.QUERY_ADD_TITOLTUTOR_REQUEST,
    idFile: number
}
export interface SetFileTutorPractiquesFailureAction {
    type: typeof ACTIONS.QUERY_ADD_TITOLTUTOR_FAILURE,
    error: string
}

export interface SetFileAcordPractiquesSuccessAction {
    type: typeof ACTIONS.QUERY_ACORDPRACTIQUES_SUCCESS,
    idFile: number
}
export interface SetFileAcordPractiquesRequestAction {
    type: typeof ACTIONS.QUERY_ACORDPRACTIQUES_REQUEST,
    idFile: number
}
export interface SetFileAcordPractiquesFailureAction {
    type: typeof ACTIONS.QUERY_ACORDPRACTIQUES_FAILED,
    error: string
}
export interface GetFileTutorPractiquesSuccessAction {
    type: typeof ACTIONS.QUERY_GETTITOLTUTOR_SUCCESS,
    fileGeneric: FileGeneric
}

export interface GetFileAcordPractiquesSuccessAction {
    type: typeof ACTIONS.QUERY_GETACORDPRACTIQUES_SUCCESS,
    fileGeneric: FileGeneric
}

export interface RemoveFileTutorPractiquesSuccessAction {
    type: typeof ACTIONS.QUERY_REMOVETITOLTUTOR_SUCCESS,
    isRemove: boolean
}

export interface RemoveFileAcordPractiquesSuccessAction {
    type: typeof ACTIONS.QUERY_REMOVEACORDPRACTIQUES_SUCCESS,
    isRemove: boolean
}

export interface enableAction {
    type: typeof ACTIONS.ENABLE_LOADER,
}

export interface disableAction {
    type: typeof ACTIONS.DISABLE_LOADER,
}

export type TutorPractiquessActions =
    TutorPractiquesRequestAction
    | TutorPractiquesSuccessAction
    | TutorPractiquesFailureAction
    | SetFileTutorPractiquesSuccessAction
    | SetFileTutorPractiquesRequestAction
    | SetFileTutorPractiquesFailureAction
    | SetFileAcordPractiquesSuccessAction
    | SetFileAcordPractiquesRequestAction
    | SetFileAcordPractiquesFailureAction
    | GetFileTutorPractiquesSuccessAction
    | GetFileAcordPractiquesSuccessAction
    | RemoveFileAcordPractiquesSuccessAction
    | RemoveFileTutorPractiquesSuccessAction
    | enableAction
    | disableAction
    ;

export const setTutorPractiques = (tutorPractiques: TutorPractiques) => {
    return async (dispatch: Dispatch<TutorPractiquessActions>) => {
        dispatch(enableLoader())
        try {
            const response = await updateTutorPractiques(tutorPractiques);
            if (response) {
                dispatch(setTutorPractiquesSuccess(tutorPractiques));
            }
            dispatch(disableLoader())

        } catch (error: any) {
            dispatch(setTutorPractiquesFailure(error))
            dispatch(disableLoader())
        }
    }
}

export const retrieveTutorPractiques = (matriculaId: string) => {
    return async (dispatch: Dispatch<TutorPractiquessActions>) => {
        dispatch(enableLoader())
        try {
            const response = await getTutorPractiquesByMatriculaId(matriculaId);
            if (response && response.data) {
                dispatch(setTutorPractiquesSuccess(response.data));
            }
            dispatch(disableLoader())
        } catch (error: any) {
            dispatch(setTutorPractiquesFailure(error))
            dispatch(disableLoader())
        }
    }
}

export const addFileTutorPractiques = (fileAlumne: FileAlumne, idMatricula: number) => {
    return async (dispatch: Dispatch<TutorPractiquessActions>) => {
        dispatch(enableLoader())
        dispatch(setFileTutorPractiquesRequest())
        try {
            const response:any = await addFileTitolDirector(fileAlumne, idMatricula);
            if (response && response.data) {
                dispatch(setFileTutorPractiquesSuccess(response.data));
            }
            if(response.data === false){
                dispatch(setFileTutorPractiquesFailure('Hi ha un error el document'))
            }
            dispatch(disableLoader())
        } catch (error: any) {
            dispatch(setFileTutorPractiquesFailure(error))
            dispatch(disableLoader())
        }
    }
}

export const addFileAcord = (fileAlumne: FileAlumne, idMatricula: number) => {
    return async (dispatch: Dispatch<TutorPractiquessActions>) => {
        dispatch(enableLoader())
        dispatch(setFileAcordPractiquesRequest())

        try {
            const response:any = await addFileAcordPractiques(fileAlumne, idMatricula);
            if (response && response.data) {
                dispatch(setFileAcordPractiquesSuccess(response.data));
            }
            if(response.data === false){
                dispatch(setFileAcordPractiquesFailure('Hi ha un error el document'))
            }

            dispatch(disableLoader())
        } catch (error: any) {
            dispatch(setFileAcordPractiquesFailure(error))
            dispatch(disableLoader())
        }
    }
}

export const removeFilePractiques = (tutorPractiques: TutorPractiques, type: string) => {
    return async (dispatch: Dispatch<TutorPractiquessActions>) => {
        dispatch(enableLoader())
        try {
            if (type === 'acord') {
                const response = await removeFileAcordPractiques(tutorPractiques);
                if (response && response.data) {
                    dispatch(removeFileAcordPractiquesSuccess(response.data));
                }
            }
            if (type === 'tutor') {
                const response = await removeFileTutorPractiques(tutorPractiques);
                if (response && response.data) {
                    dispatch(removeFileTutorPractiquesSuccess(response.data));
                }
            }
            dispatch(disableLoader())
        } catch (error: any) {
            dispatch(setTutorPractiquesFailure(error))
            dispatch(disableLoader())
        }
    }
}


export const getFile = (fileId: number, type: string) => {
    return async (dispatch: Dispatch<TutorPractiquessActions>) => {
        dispatch(enableLoader())
        try {
            const response = await getFileById(fileId);
            if (response && response.data) {
                if (type === 'tutor') {
                    dispatch(getFileTutorPractiquesSuccess(response.data));
                }
                if (type === 'acord') {
                    dispatch(getFileAcordPractiquesSuccess(response.data));
                }
            }
            dispatch(disableLoader())
        } catch (error: any) {
            dispatch(setTutorPractiquesFailure(error))
            dispatch(disableLoader())

        }
    }
}


const TutorPractiquesReducer = (state = InitialState, action: TutorPractiquessActions):
    { error: string; tutorPractiques: {}, idFile: number, fileGeneric: {}, status: string } => {
    let json = {};
    switch (action.type) {
        case ACTIONS.ENABLE_LOADER:
            return{
                ...state,
                status: 'loading'
            }
        case ACTIONS.QUERY_TUTORPRACTIQUES_REQUEST:
            return {
                ...state,
                error: ''
            };
        case ACTIONS.QUERY_ADD_TITOLTUTOR_REQUEST:
            return {
                ...state,
                error: ''
            };
        case ACTIONS.QUERY_ADD_TITOLTUTOR_REQUEST:
            return {
                ...state,
                error: ''
            };
        case ACTIONS.QUERY_TUTORPRACTIQUES_SUCCESS:
            return {
                ...state,
                status: 'completed',
                tutorPractiques: "tutorPractiques" in action ? action.tutorPractiques : {}
            };
        case ACTIONS.QUERY_GETTITOLTUTOR_SUCCESS:
            if ("fileGeneric" in action && action.fileGeneric) {
                state.tutorPractiques.p_titol_files_name = action.fileGeneric.file_name;
                state.tutorPractiques.p_titol_files_url = action.fileGeneric.file_url + action.fileGeneric.file;
            }
            return {
                ...state,
                tutorPractiques: state.tutorPractiques,
                status: 'completed',
                fileGeneric: "fileGeneric" in action ? action.fileGeneric : {}
            };
        case ACTIONS.QUERY_GETACORDPRACTIQUES_SUCCESS:
            if ("fileGeneric" in action && action.fileGeneric) {
                state.tutorPractiques.p_fitxa_files_name = action.fileGeneric.file_name;
                state.tutorPractiques.p_fitxa_files_url = action.fileGeneric.file_url + action.fileGeneric.file;
            }
            return {
                ...state,
                status: 'completed',
                tutorPractiques: state.tutorPractiques,
                fileGeneric: "fileGeneric" in action ? action.fileGeneric : {}
            };
        case ACTIONS.QUERY_ADD_TITOLTUTOR_SUCCESS:
            if ("idFile" in action && action.idFile) {
                state.tutorPractiques.p_titol_files = action.idFile
            }
            return {
                ...state,
                status: 'completed',
                tutorPractiques: state.tutorPractiques,
                idFile: "idFile" in action ? action.idFile : 0
            };
        case ACTIONS.QUERY_ACORDPRACTIQUES_SUCCESS:
            if ("idFile" in action && action.idFile) {
                state.tutorPractiques.p_fitxa_files = action.idFile
            }
            return {
                ...state,
                tutorPractiques: state.tutorPractiques,
                status: 'completed',
                idFile: "idFile" in action ? action.idFile : 0
            };
        case ACTIONS.QUERY_REMOVEACORDPRACTIQUES_SUCCESS:
            if ("isRemove" in action && action.isRemove) {
                state.tutorPractiques.p_fitxa_files_name = '';
                state.tutorPractiques.p_fitxa_files_url = '';
                state.tutorPractiques.p_fitxa_files = 0;
            }
            return {
                ...state,
                status: 'completed',
                tutorPractiques: state.tutorPractiques,
            };
        case ACTIONS.QUERY_REMOVETITOLTUTOR_SUCCESS:
            if ("isRemove" in action && action.isRemove) {
                state.tutorPractiques.p_titol_files_name = '';
                state.tutorPractiques.p_titol_files_url = '';
                state.tutorPractiques.p_titol_files = 0;
            }
            return {
                ...state,
                status: 'completed',
                tutorPractiques: state.tutorPractiques,
            };
        case ACTIONS.QUERY_TUTORPRACTIQUES_FAILURE:
            const err = "error" in action ? action.error : '';
            return {
                ...state,
                status: 'error',
                error: err
            };
        case ACTIONS.QUERY_ADD_TITOLTUTOR_FAILURE:
            json = {
                type: 'tutor',
                message: 'Error al pujar el document del tutor'
            }
            return {
                ...state,
                status: 'error',
                error: JSON.stringify(json)
            };
        case ACTIONS.QUERY_ACORDPRACTIQUES_SUCCESS:
            json = {
                type: 'acord',
                message: 'Error al pujar el document del acord de pràctiques'
            }
            return {
                ...state,
                status: 'error',
                error: JSON.stringify(json)
            };
        case ACTIONS.DISABLE_LOADER:
            return{
                ...state,
                status: 'idle'
            }
        default:
            return state;
    }
};


export {TutorPractiquesReducer};
