import {api} from "../services/api";
import {Alumne} from "../types/alumne";

export async function findAlumne() {
    if(localStorage.getItem('user')){
        const userData = JSON.parse(localStorage.getItem('user') as string);
        return await api.post<Alumne>("/get_alumne_by_username", userData);
    }
}

export async function setStep(idMatricules:number,step:number) {
    if(localStorage.getItem('user')){
        const userData = JSON.parse(localStorage.getItem('user') as string);
        userData['idMatricules'] = idMatricules;
        userData['step'] = step;
        return await api.post<number>("/set_step", userData);
    }
}

export async function setCentre(idMatricules:number,idCentre:number) {
    if(localStorage.getItem('user')){
        const userData = JSON.parse(localStorage.getItem('user') as string);
        userData['idMatricules'] = idMatricules;
        userData['idCentre'] = idCentre;
        return await api.post<number>("/set_centre_matricula", userData);
    }
}

export async function setEndPractiques(idMatricules:number,end_practiques:number) {
    if(localStorage.getItem('user')){
        const userData = JSON.parse(localStorage.getItem('user') as string);
        userData['idMatricules'] = idMatricules;
        userData['end_practiques'] = end_practiques;
        return await api.post<number>("/set_end_practiques", userData);
    }
}

export async function updateConvocatoria(idMatricules:number) {
    if(localStorage.getItem('user')){
        const userData = JSON.parse(localStorage.getItem('user') as string);
        userData['idMatricules'] = idMatricules;
        return await api.post<Alumne>("/send_convocatoria", userData);
    }
}
