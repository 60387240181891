import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import Component from './Step2.Component';
import {getUser} from "../../redux/reducers/userReducer";
import {getEntity, getEntityByUser, updateEntity} from "../../redux/reducers/entitatsReducer";
import {getAlumne, getIsInProgress, updateStep} from "../../redux/reducers/alumneReducer";
import {getProvincies} from "../../redux/reducers/provinciesReducer";
import {
    addFileAcord,
    addFileTutorPractiques,
    getErrorTutor,
    getStatusTutorPractiques,
    getTutorPractiques,
    removeFilePractiques,
    retrieveTutorPractiques,
    setTutorPractiques
} from "../../redux/reducers/tutorPractiquesReducer";


const mapStateToProps = createStructuredSelector<any, any>({
    user: getUser,
    entity: getEntityByUser,
    alumne: getAlumne,
    provincies: getProvincies,
    tutorPractiquesSaved: getTutorPractiques,
    errorTutor: getErrorTutor,
    statusTutor: getStatusTutorPractiques,
    inProgressAlumne: getIsInProgress,
});

const mapDispatchToProps = {
    updateEntity: updateEntity,
    updateTutorPractiques: setTutorPractiques,
    retrieveTutorPractiques: retrieveTutorPractiques,
    addFileTutor: addFileTutorPractiques,
    addFilePractiques: addFileAcord,
    removeFilePractiques: removeFilePractiques,
    setStep: updateStep,
    getEntity: getEntity,
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
