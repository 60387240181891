import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './ForgotPasswordForm.Component';
import {
    createNewPassword,
    getError,
    getIsInProgress,
    getIsCompleted,
    clearProcess,
} from "../../redux/reducers/userReducer";


const mapStateToProps = createStructuredSelector<any,any>({
    isInProgress: getIsInProgress,
    isCompleted: getIsCompleted,
    error: getError
});

const mapDispatchToProps = {
    createNewPassword:  createNewPassword,
    clearProcess:  clearProcess
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
