import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './ButtonDownloadPdf.Component';
import {AppState} from "../../redux/store/store";
import React from "react";

interface ButtonProps{
    title : string
    nameDocument : string
}

const mapStateToProps = (state:AppState, props:ButtonProps) => {
    return createStructuredSelector<any,any>({
    });
};

const mapDispatchToProps = {
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
