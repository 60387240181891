import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {User} from "../../types/user";
import {useHistory} from "react-router-dom";
import Modal from "../Modal";

const ForgotPasswordForm: React.FC = ({isInProgress,createNewPassword,isCompleted,error, clearProcess}: any) => {

    const [userLogin,setUserLogin] = useState<User>({
        username:'',
        password: '',
        token: ''
    })
    const history = useHistory();
    const [openModal, setOpenModal] = useState(false)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        if(isCompleted){
            setOpenModal(true);
            setDisabled(false);
        }
    },[isInProgress]);

    const handleNewPassword = (e: React.FormEvent<HTMLFormElement>):void =>{
        e.preventDefault();
        createNewPassword(userLogin);
        setDisabled(true);
    }

    const userChange = (name:any,event: React.ChangeEvent<HTMLInputElement>):void =>{
        const value  = event.target.value;

        setUserLogin((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    };

    const closeModal = ():void=>{
        clearProcess();
        setOpenModal(false);
        history.push("/pas1");
    }


    return (
        <>
            <form
                onSubmit={(e)=>{handleNewPassword(e)}}>
                <div className="mt-4 ">
                    <div>
                        <label className="block" htmlFor="email">Email</label>
                        <input type="text" placeholder="Email"
                               className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none
                                            focus:ring-orange-600"
                               onChange={(e) => {
                                   userChange('username',e);
                               }}
                        />
                    </div>
                    <div className="flex items-baseline justify-center">
                        <button
                            className={disabled ?
                                "px-6 py-2 mt-4 text-white bg-gray-200 rounded-lg" :
                                "px-6 py-2 mt-4 text-white bg-orange-500 rounded-lg hover:bg-orange-900"}

                            disabled={disabled}
                        >
                            Crear un nou password
                        </button>
                    </div>
                </div>
                <Modal open={openModal} setOpen={()=>{
                    closeModal()
                }}>
                    <p className="mx-auto">
                        { error &&
                            'No existeix usuari'
                        }
                        {
                            !error &&
                                'Has rebut un correu electrònic amb el teu nou correu.\n'
                        }
                    </p>
                </Modal>
            </form>
        </>
    )

}

export default withTranslation('common')(ForgotPasswordForm);
