import React from "react";
import {Alumne} from "../../types/alumne";

class Pas4QualificationMessage extends React.Component<{ endConvocatoria: boolean, alumne:Alumne }> {
    render() {
        return <>
            {
                this.props.endConvocatoria
                && (this.props.alumne.tipus_curs === 'Director'
                    && this.props.alumne.m_qualificacio
                    && this.props.alumne.m_qualificacio !== 'Pendent de correccions'
                    && this.props.alumne.m_qualificacio !== 'Pendent de qualificació'
                    && this.props.alumne.m_qualificacio_projecte
                    && this.props.alumne.m_qualificacio_projecte !== 'Pendent de correccions'
                    && this.props.alumne.m_qualificacio_projecte !== 'Pendent de qualificació'
                ) &&
                <div className="flex flex-col px-8 py-6 mt-4 text-left bg-white w-4/5 	">
                    <div className="border-solid border-t-2 p-4 border-orange-600 shadow-lg">
                        <p className="text-center text-justify text-lg mb-4">
                            La qualificació de la teva memòria de pràctiques és {this.props.alumne.m_qualificacio}
                            i la del projecte {this.props.alumne.m_qualificacio_projecte}
                        </p>
                    </div>
                </div>
            }
            {
                this.props.endConvocatoria
                && (this.props.alumne.tipus_curs === 'Monitor'
                    && this.props.alumne.m_qualificacio
                    && this.props.alumne.m_qualificacio !== 'Pendent de correccions'
                    && this.props.alumne.m_qualificacio !== 'Pendent de qualificació'
                ) &&
                <div className="flex flex-col px-8 py-6 mt-4 text-left bg-white w-4/5 	">
                    <div className="border-solid border-t-2 p-4 border-orange-600 shadow-lg">
                        <p className="text-center text-justify text-lg mb-4">
                            La qualificació de la teva memòria de pràctiques és {this.props.alumne.m_qualificacio}
                        </p>
                    </div>
                </div>
            }
        </>;
    }
}

export default Pas4QualificationMessage;