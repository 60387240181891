import {createSelector} from 'reselect'
import {AppState} from "../store/store";
import {Dispatch} from "redux";
import {FileAlumne, FileGeneric} from "../../types/alumne";
import {addFileMemoria, addFileProjecte, deleteFileMemoria, deleteFileProjecte} from "../../services/document";
import {getFileById} from "../../services/file";

export const ACTIONS = {
    QUERY_DOCUMENT_REQUEST: 'QUERY_DOCUMENT_REQUEST',
    QUERY_DOCUMENT_FAILURE: 'QUERY_DOCUMENT_FAILURE',
    GET_MEMORIA_SUCCESS: 'GET_MEMORIA_SUCCESS',
    GET_PROJECTE_SUCCESS: 'GET_PROJECTE_SUCCESS',
    POST_MEMORIA_SUCCESS: 'POST_MEMORIA_SUCCESS',
    POST_PROJECTE_SUCCESS: 'POST_PROJECTE_SUCCESS',
    DELETE_MEMORIA_SUCCESS: 'DELETE_MEMORIA_SUCCESS',
    DELETE_PROJECTE_SUCCESS: 'DELETE_PROJECTE_SUCCESS',
    ENABLE_LOADER: 'ENABLE_LOADER',
    DISABLE_LOADER: 'DISABLE_LOADER',
};

const InitialState = {
    memoria: {} as FileGeneric,
    projecte: {} as FileGeneric,
    inProgress: false,
    isRemove: false,
    idFile: 0,
    error: '',
    status: 'idle', //idle||loading||completed||failed
}

const getSlice = (state: AppState) => state.documentsPractiques;

export const getStatusDocuments = createSelector(
    getSlice,
    ({status}) => status,
);

export const getMemoria = createSelector(
    getSlice,
    ({memoria}) => memoria,
);

export const getProjecte = createSelector(
    getSlice,
    ({projecte}) => projecte,
);
export const getInProgress = createSelector(
    getSlice,
    ({inProgress}) => inProgress,
);

export const getFileId = createSelector(
    getSlice,
    ({idFile}) => idFile,
);
export const setInProgress = (): DocumentActions => ({
    type: ACTIONS.QUERY_DOCUMENT_REQUEST,
})


export const setDocumentFailure = (error: string): DocumentActions => ({
    type: ACTIONS.QUERY_DOCUMENT_FAILURE,
    error
})

export const getMemoriaSuccess = (memoria: FileGeneric): DocumentActions => ({
    type: ACTIONS.GET_MEMORIA_SUCCESS,
    memoria
})
export const getProjecteSuccess = (projecte: FileGeneric): DocumentActions => ({
    type: ACTIONS.GET_PROJECTE_SUCCESS,
    projecte
})

export const postMemoriaSuccess = (idFile: number): DocumentActions => ({
    type: ACTIONS.POST_MEMORIA_SUCCESS,
    idFile
})
export const postProjecteSuccess = (idFile: number): DocumentActions => ({
    type: ACTIONS.POST_PROJECTE_SUCCESS,
    idFile
})

export const deleteMemoriaSuccess = (isRemove: boolean): DocumentActions => ({
    type: ACTIONS.DELETE_MEMORIA_SUCCESS,
    isRemove
})

export const deleteProjecteSuccess = (isRemove: boolean): DocumentActions => ({
    type: ACTIONS.DELETE_PROJECTE_SUCCESS,
    isRemove
})


export interface DocumentRequestAction {
    type: typeof ACTIONS.QUERY_DOCUMENT_REQUEST
}

export interface DocumentFailureAction {
    type: typeof ACTIONS.QUERY_DOCUMENT_FAILURE,
    error: string
}

export interface GetMemoriaSuccessAction {
    type: typeof ACTIONS.GET_MEMORIA_SUCCESS,
    memoria: FileGeneric
}

export interface GetProjecteSuccessAction {
    type: typeof ACTIONS.GET_PROJECTE_SUCCESS,
    projecte: FileGeneric
}

export interface PostMemoriaSuccessAction {
    type: typeof ACTIONS.POST_MEMORIA_SUCCESS,
    idFile: number
}

export interface PostProjecteSuccessAction {
    type: typeof ACTIONS.POST_PROJECTE_SUCCESS,
    idFile: number
}

export interface DeleteMemoriaSuccessAction {
    type: typeof ACTIONS.DELETE_MEMORIA_SUCCESS,
    isRemove: boolean
}

export interface DeleteProjecteSuccessAction {
    type: typeof ACTIONS.DELETE_PROJECTE_SUCCESS,
    isRemove: boolean
}

export const enableLoader = () => {
    return {
        type: ACTIONS.ENABLE_LOADER,
    };
}

export const disableLoader = () => {
    return {
        type: ACTIONS.DISABLE_LOADER,
    };
}

export interface enableAction {
    type: typeof ACTIONS.ENABLE_LOADER,
}

export interface disableAction {
    type: typeof ACTIONS.DISABLE_LOADER,
}


export type DocumentActions =
    DocumentRequestAction
    | DocumentFailureAction
    | GetMemoriaSuccessAction
    | GetProjecteSuccessAction
    | PostMemoriaSuccessAction
    | PostProjecteSuccessAction
    | DeleteMemoriaSuccessAction
    | DeleteProjecteSuccessAction
    | enableAction
    | disableAction
    ;

export const addMemoria = (fileAlumne: FileAlumne, idMatricula: number) => {
    return async (dispatch: Dispatch<DocumentActions>) => {
        dispatch(setInProgress())
        dispatch(enableLoader())
        try {
            const response = await addFileMemoria(fileAlumne, idMatricula);
            if (response && response.data) {
                dispatch(postMemoriaSuccess(response.data));
            } else {
                dispatch(setDocumentFailure('Error al pujar el document'))
            }
            dispatch(disableLoader())
        } catch (error: any) {
            console.log(error);
            dispatch(setDocumentFailure(error))
            dispatch(disableLoader())

        }
    }
}

export const addProjecte = (fileAlumne: FileAlumne, idMatricula: number) => {
    return async (dispatch: Dispatch<DocumentActions>) => {
        dispatch(setInProgress())
        dispatch(enableLoader())
        try {
            const response = await addFileProjecte(fileAlumne, idMatricula);
            if (response && response.data) {
                dispatch(postProjecteSuccess(response.data));
            } else {
                dispatch(setDocumentFailure('Error al pujar el document'))
            }
            dispatch(disableLoader())
        } catch (error: any) {
            dispatch(setDocumentFailure(error))
            dispatch(disableLoader())
        }
    }
}

export const removeFile = (fileId: number, idMatricula: number, type: string) => {
    return async (dispatch: Dispatch<DocumentActions>) => {
        dispatch(setInProgress())
        dispatch(enableLoader())
        try {
            if (type === 'memoria') {
                const response = await deleteFileMemoria(fileId, idMatricula);
                if (response && response.data) {
                    dispatch(deleteMemoriaSuccess(response.data));
                }
            }
            if (type === 'projecte') {
                const response = await deleteFileProjecte(fileId, idMatricula);
                if (response && response.data) {
                    dispatch(deleteProjecteSuccess(response.data));
                }
            }
            dispatch(disableLoader())
        } catch (error: any) {
            dispatch(setDocumentFailure(error))
            dispatch(disableLoader())
        }
    }
}


export const getFile = (fileId: number, type: string) => {
    return async (dispatch: Dispatch<DocumentActions>) => {
        dispatch(setInProgress())
        dispatch(enableLoader())
        try {
            const response = await getFileById(fileId);
            if (response && response.data) {
                if (type === 'memoria') {
                    dispatch(getMemoriaSuccess(response.data));
                }
                if (type === 'projecte') {
                    dispatch(getProjecteSuccess(response.data));
                }
            }
            dispatch(disableLoader())
        } catch (error: any) {
            dispatch(setDocumentFailure(error))
            dispatch(disableLoader())
        }
    }
}


const documentReducer = (state = InitialState, action: DocumentActions):
    { inProgress: boolean; error: string; projecte: {}, idFile: number, memoria: {}, status: string} => {
    switch (action.type) {
        case ACTIONS.QUERY_DOCUMENT_REQUEST:
            return {
                ...state,
                inProgress: true,
                error: ''
            };
        case ACTIONS.POST_PROJECTE_SUCCESS:
            if ("idFile" in action && action.idFile) {
                state.projecte.file = action.idFile
            }
            return {
                ...state,
                inProgress: false,
                projecte: state.projecte,
                status: 'completed-projecte',
                idFile: "idFile" in action ? action.idFile : 0
            };
        case ACTIONS.POST_MEMORIA_SUCCESS:
            console.log(state);
            if ("idFile" in action && action.idFile) {
                state.memoria.file = action.idFile
            }
            return {
                ...state,
                inProgress: false,
                memoria: state.memoria,
                status: 'completed-memoria',
                idFile: "idFile" in action ? action.idFile : 0
            };
        case ACTIONS.DELETE_PROJECTE_SUCCESS:
            state.projecte.file = 0
            state.projecte.file_url = ''
            state.projecte.file_name = ''
            return {
                ...state,
                inProgress: false,
                projecte: state.projecte,
                idFile: 0,
                status: 'completed',
            };
        case ACTIONS.DELETE_MEMORIA_SUCCESS:
            state.memoria.file = 0
            state.memoria.file_url = ''
            state.memoria.file_name = ''
            return {
                ...state,
                inProgress: false,
                memoria: state.memoria,
                status: 'completed',
                idFile: -1
            };
        case ACTIONS.GET_MEMORIA_SUCCESS:
            return {
                ...state,
                inProgress: false,
                status: 'completed',
                memoria: "memoria" in action ? action.memoria : {}
            };
        case ACTIONS.GET_PROJECTE_SUCCESS:
            return {
                ...state,
                inProgress: false,
                status: 'completed',
                projecte: "projecte" in action ? action.projecte : {}
            };
        case ACTIONS.QUERY_DOCUMENT_FAILURE:
            return {
                ...state,
                inProgress: false,
                status: 'failed',
                error: "error" in action ? action.error : ''
            };
        case ACTIONS.ENABLE_LOADER:
            return{
                ...state,
                status: 'loading'
            }
        case ACTIONS.DISABLE_LOADER:
            return{
                ...state,
                status: 'idle'
            }
        default:
            return state;
    }
};


export {documentReducer};
