import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {User} from "../../types/user";
import {useHistory} from "react-router-dom";
import Modal from "../Modal";

const LoginForm: React.FC = ({user, isInProgress, retrieveUser, validateUser}: any) => {

    const [userLogin, setUserLogin] = useState<User>({
        username: '',
        password: '',
        token: ''
    })
    const history = useHistory();
    const [openModal, setOpenModal] = useState(false)

    function isEmpty(obj: {}) {
        for (var prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }
        return JSON.stringify(obj) === JSON.stringify({});
    }


    useEffect(() => {
        if (!isInProgress && user && user.is_userapp) {
            user.password = '';
            localStorage.setItem('user', JSON.stringify(user));
            history.push("/pas1");
        }

        if (!isInProgress && isEmpty(user)) {
            const userLocalStorage: User = JSON.parse(localStorage.getItem('user') as string);
            validateUser(userLocalStorage);
        }
        if (user === false) {
            setOpenModal(true);
        }


    }, [user, isInProgress]);

    const handleLogin = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        retrieveUser(userLogin);
    }

    const handleForgotPassword = (): void => {
        history.push("/forgotPassword");
    }

    const userChange = (name: any, event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;

        setUserLogin((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    };


    return (
        <>
            <form
                onSubmit={(e) => {
                    handleLogin(e)
                }}>
                <div className="mt-4 ">
                    <div>
                        <label className="block" htmlFor="email">Email</label>
                        <input type="text" placeholder="Email"
                               className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none
                                            focus:ring-orange-600"
                               onChange={(e) => {
                                   userChange('username', e);
                               }}
                        />
                    </div>
                    <div className="mt-4">
                        <label className="block">DNI</label>
                        <input type="password" placeholder="DNI"
                               className="w-full px-4 py-2 mt-25  border rounded-md focus:outline-none
                                            focus:ring-orange-600"
                               onChange={(e) => {
                                   userChange('password', e);
                               }}
                        />
                    </div>
                    <div className="flex items-baseline justify-center">
                        <button
                            className="px-6 py-2 mt-4 text-white bg-orange-500 rounded-lg hover:bg-orange-900">
                            Entrar
                        </button>
                    </div>
                </div>
            </form>
            {
                openModal &&
                <>
                    <Modal open={openModal} setOpen={setOpenModal}>
                        <div className='flex flex-col items-center justify-center'>
                        <p>Hi ha un error amb el teu usuari o contrasenya.</p>
                        <p>A més, si la convocatòria ha passat la data de convocatòria màxima, l'alumnat no podrà accedir.</p>
                            <button
                                onClick={() => setOpenModal(false)}
                                className="px-6 py-2 mt-4 text-white bg-orange-500 rounded-lg hover:bg-orange-900">
                                Tancar
                            </button>
                        </div>

                    </Modal>
                </>
            }
        </>
    )

}

export default withTranslation('common')(LoginForm);
