import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Step3.Component';
import {validateToken} from "../../redux/reducers/userReducer";
import {retrieveEntities} from "../../redux/reducers/entitatsReducer";
import {getAlumne, retrieveAlumne, updateEndPractiques, updateStep} from "../../redux/reducers/alumneReducer";


const mapStateToProps = createStructuredSelector<any,any>({
    alumne: getAlumne
});

const mapDispatchToProps = {
    retrieveUser : validateToken,
    retrieveEntities : retrieveEntities,
    retrieveAlumne: retrieveAlumne,
    updateEndPractiques: updateEndPractiques,
    setStep: updateStep
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);