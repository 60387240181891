import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Entity} from "../../types/entity";
import EntityForm from "../../components/EntityForm";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import {useHistory} from "react-router-dom";
import SubHeader from "../../components/SubHeader";

const removeDiacritics = (text: string): string => {
    return text
        .normalize("NFD") // descompone caracteres diacríticos
        .replace(/[\u0300-\u036f]/g, ""); // elimina diacríticos
};

const Step1: React.FC = ({retrieveEntities,retrieveAlumne,entities,clearAlumne,clearUser, alumne,newEntity,status, setStep,setCentre,retrieveProvincies,error,getEntity}: any) => {

    const [query, setQuery] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [closePractiques, setClosePractiques] = useState(false)
    const [openModalError, setOpenModalError] = useState(false)
    const [entity, setEntity] = useState<Entity>({
        rao_social : '',
        cif : ''
    })
    const [listEntities, setListEntities] = useState<Entity[]>([])
    const history = useHistory();
    const normalizedQuery = removeDiacritics(query.toLowerCase());
    const [message, setMessage] = useState('');

    useEffect(() => {
        retrieveEntities();
        retrieveAlumne();
        retrieveProvincies();
    }, []);

    useEffect(() => {
       setListEntities(entities);
       if(newEntity && status === 'completed' ){
        setMessage("Estem tramitant el teu conveni quan estigui resolt t'enviarem un correu electrònic perquè puguis continuar el procés de comunicació de pràctiques.")
        setOpenModalError(true);
       }
    }, [newEntity, status]);

    useEffect(()=>{
        console.log(status);
    }, [status])

    useEffect(()=>{
        setListEntities(entities);
        
    },[entities])

    useEffect(() => {
        if(error !== "isEmpty" && error!==''){
            setOpenModalError(true);
        }
        if(isEmpty(alumne) && error==="isEmpty"){
            setClosePractiques(true)
        }
    }, [error, alumne]);

    function isEmpty(obj:{}) {
        for(var prop in obj) {
            if(Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    const handleSeguent = ()=>{
        let stepAlumne = parseInt(alumne.step)? parseInt(alumne.step)+ 1 : 2;
        setStep(alumne.idMatricules,stepAlumne);
        setCentre(alumne.idMatricules,entity.id);
        getEntity(entity.id)
        history.push('/pas'+ stepAlumne);
    }

    const handleClosePractiques = ()=>{
        localStorage.removeItem("user");
        clearUser();
        clearAlumne();
        history.push("/")
    }

    return (
        <>
            <div className="flex flex-col items-center justify-between min-h-screen bg-white w-full">
               <Header />
                <SubHeader title="1. CENTRE DE PRÀCTIQUES"
                           description="Cerca aquí el centre de pràctiques amb la qual faràs les pràctiques. I selecciona la teva." />

                <div className="flex flex-col px-8 py-6 mt-4 text-left bg-white w-4/5	">
                    <input  className="w-full px-4 py-2 mt-25  border rounded-md focus:outline-none
                                            focus:ring-orange-600"
                            placeholder="Cerca aquí el centre de pràctiques amb la qual faràs les pràctiques. Per nom o CIF"
                            value={entity.rao_social}
                            onChange={event => {
                                setQuery(event.target.value)
                                setEntity({
                                    rao_social : event.target.value,
                                    cif : ''
                                })
                            }} />
                    <div className="pt-4">
                        <div className="grid grid-cols-2  fixed-top border p-4  bg-slate-300">
                            <p>Nom del centre de pràctiques</p>
                            <p>Cif</p>
                        </div>
                    </div>
                    <div className="h-52 scroll-auto overflow-auto">
                        {
                            listEntities
                                .filter((post: Entity) => {
                                    const normalizedRaoSocial = removeDiacritics(post.rao_social.toLowerCase());
                                    const normalizedCif = removeDiacritics(post.cif.toLowerCase());

                                    if (normalizedQuery === '') {
                                        return true;
                                    }

                                    return (
                                        normalizedRaoSocial.includes(normalizedQuery) ||
                                        normalizedCif.includes(normalizedQuery)
                                    );
                                })
                                .map((post: Entity, index: number) => (
                                    <div
                                        className="grid grid-cols-2 border p-4 hover:bg-slate-200 cursor-pointer"
                                        key={index}
                                        onClick={() => {
                                            setEntity(post);
                                            setOpenModal(!openModal);
                                        }}
                                    >
                                        <p>{post.rao_social}</p>
                                        <p>{post.cif}</p>
                                    </div>
                                ))
                        }
                    </div>

                    <div className="pt-4">
                        <p>
                        Si no trobes el teu centre de pràctiques vol dir que hem de
                        tramitar un nou conveni de col·laboració entre aquest centre i l'Escola lliure el Sol, aquest és un
                        acord que es signa un sol cop per acollir alumnes en pràctiques. Sense aquest conveni omplert i signat correctament NO ES PODEN FER LES
                        PRÀCTIQUES!
                        <br></br>
                            {/* <ButtonDownloadPdf
                                title={'Conveni de pràctiques'}
                                nameDocument={'conveni_practiques.pdf'}
                            /> */}
                        </p>
                    </div>
                    <Modal open={closePractiques} setOpen={setClosePractiques} onClose={()=>handleClosePractiques()}>
                        <p className="mx-auto">
                           Aquest usuari no te practiques en curs
                        </p>
                        <Button title="Tancar"
                                className="mx-auto"
                                onClick={e => {handleClosePractiques();}} />
                    </Modal>
                    <Modal open={openModal} setOpen={setOpenModal}>
                        <p className="mx-auto">
                            Has seleccionat <strong>{entity.rao_social}</strong> estas segur?
                        </p>
                        <Button title="Desar"
                                className="mx-auto"
                                onClick={e => {handleSeguent();}} />
                    </Modal>
                    <Modal open={openModalError} 
                        setOpen={() => {
                        setOpenModalError(!openModalError);
                        setMessage("");
                    }}>
                        {   error && <p className="mx-auto">
                                {error}
                            </p>
                        }
                        {message !== '' &&
                                <p>{message}</p>
                        }               

                        <Button title="Tancar"
                                className="mx-auto"
                                onClick={e => { 
                                    setOpenModalError(!openModalError);
                                    setMessage("");
                                }} />
                    </Modal>
                    <EntityForm />
                </div>
                <Footer />
            </div>
        </>
    )

}

export default withTranslation('common')(Step1);
