import React from "react";
import {Alumne} from "../../types/alumne";

class Pas4FinalMessage extends React.Component<{ endConvocatoria: boolean, alumne:Alumne }> {
    render() {
        return <>
            {
                this.props.endConvocatoria && (this.props.alumne.m_data_entrega && this.props.alumne.m_data_entrega !== '0000-00-00') &&
                <div className="flex flex-col px-8 py-6 mt-4 text-left bg-white w-4/5 	">
                    <div className="border-solid border-t-2 p-4 border-orange-600 shadow-lg">
                        <p className="text-center text-justify text-lg mb-4">
                            La teva memòria
                            {
                                this.props.alumne.tipus_curs === 'Director' && <> i projecte s'han </>
                            }
                            {
                                this.props.alumne.tipus_curs !== 'Director' && <> s'ha</>
                            }
                            rebut correctament. Recorda que per a ser avaluat/da, has d’entregar el certificat
                            final de les
                            pràctiques a l’escola amb la signatura original. Et recomanem fer-ho per correu
                            postal certificat.
                        </p>
                        <p className="text-center text-justify text-lg mb-4">
                            Per al cas en que ja hagis entregat la memòria i el certificat, la propera
                            convocatòria finalitza el
                            dia {this.props.alumne.convocatoria_tramitacio}. Un cop sigui valorada pel teu tutor/a,
                            rebràs una comunicació informant-te de la teva situació.
                        </p>
                        <p className="text-center text-justify text-lg mb-4">
                            Penja el document en format PDF
                        </p>
                        <p className="text-center text-justify text-lg mb-4">
                            Recorda que en el moment de penjar la memòria has d’haver entregat el certificat
                            de pràctiques per tal que puguem certificar que l’etapa pràctica està superada!
                        </p>
                        <p className="text-center text-justify text-lg mb-4">
                            En cas de no fer-ho, pots quedar fora de la convocatòria.
                            Gràcies!
                        </p>
                    </div>
                </div>
            }
        </>;
    }
}

export default Pas4FinalMessage;
