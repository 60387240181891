import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LoginForm from "../../components/LoginForm";

const Login: React.FC = ({}: any) => {


    useEffect(() => {
    }, []);

    return (
        <>
            <div className="flex flex-col items-center justify-between  min-h-screen bg-white">
               <Header title={"Comunicació de pràctiques i entrega de memòria"}/>
                <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
                    <h3 className="text-2xl font-bold text-center">Inicieu sessió al vostre compte</h3>
                    <LoginForm />
                </div>
                <Footer />
            </div>
        </>
    )

}

export default withTranslation('common')(Login);