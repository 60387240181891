import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Select.Component';
import {AppState} from "../../redux/store/store";
import React from "react";

interface SelectProps{
    title : string;
    className?: string;
    name?:string;
    value?:number;
    required?: boolean;
    onChange?:
        | ((event: React.MouseEvent<HTMLSelectElement>) => void)
        | undefined;
    list : any;
}

const mapStateToProps = (state:AppState, props:SelectProps) => {
    return createStructuredSelector<any,any>({
    });
};

const mapDispatchToProps = {
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);