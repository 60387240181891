import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Footer.Component';
import {getAlumne, getStep, updateStep} from "../../redux/reducers/alumneReducer";
import React from "react";
import {AppState} from "../../redux/store/store";


interface FooterProps{
    onClickNext?:
        | ((event: React.MouseEvent<HTMLButtonElement>) => void)
        | undefined;
}

const mapStateToProps = (state:AppState, props:FooterProps) => {
    return createStructuredSelector<any,any>({
        alumne:getAlumne,
        step: getStep,
    });
};

const mapDispatchToProps = {
    setStep: updateStep
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
