import React from "react";
import {withTranslation} from "react-i18next";
import {Redirect, Route} from "react-router-dom";

const PrivateRoute: React.FC = ({user,path,children,...rest}: any) => {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                user && user.is_userapp ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    )

}

export default withTranslation('common')(PrivateRoute);