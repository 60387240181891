import { createSelector } from 'reselect'
import {AppState} from "../store/store";
import {Dispatch} from "redux";
import {Alumne} from "../../types/alumne";
import {findAlumne, setCentre, updateConvocatoria, setEndPractiques, setStep} from "../../services/alumne";
import {UsersActions} from "./userReducer";

export const ACTIONS = {
    QUERY_ALUMNE_REQUEST: 'QUERY_ALUMNE_REQUEST',
    QUERY_ALUMNE_SUCCESS: 'QUERY_ALUMNE_SUCCESS',
    QUERY_ALUMNE_FAILURE: 'QUERY_ALUMNE_FAILURE',
    QUERY_STEP_REQUEST: 'QUERY_STEP_REQUEST',
    QUERY_STEP_SUCCESS: 'QUERY_STEP_SUCCESS',
    QUERY_STEP_FAILURE: 'QUERY_STEP_FAILURE',
    QUERY_CENTRE_REQUEST: 'QUERY_CENTRE_REQUEST',
    QUERY_CENTRE_SUCCESS: 'QUERY_CENTRE_SUCCESS',
    QUERY_CENTRE_FAILURE: 'QUERY_CENTRE_FAILURE',
    QUERY_END_PRACTIQUES_REQUEST: 'QUERY_END_PRACTIQUES_REQUEST',
    QUERY_END_PRACTIQUES_SUCCESS: 'QUERY_END_PRACTIQUES_SUCCESS',
    QUERY_END_PRACTIQUES_FAILURE: 'QUERY_END_PRACTIQUES_FAILURE',
    QUERY_SEND_CONVOCATORIA_SUCCESS: 'QUERY_SEND_CONVOCATORIA_SUCCESS',
};

const InitialState = {
    alumne : {} as Alumne,
    step: 0,
    idCentre: 0,
    endPractiques: 0,
    inProgress : false,
    error: ''
}

const getSlice = (state: AppState) => state.alumne;

export const getAlumne = createSelector(
    getSlice,
    ({alumne}) => alumne,
);

export const getStep = createSelector(
    getSlice,
    ({step}) => step,
);

export const getEndPractiques = createSelector(
    getSlice,
    ({endPractiques}) => endPractiques,
);


export const getIsInProgress = createSelector(
    getSlice,
    ({inProgress}) => inProgress,
);

export const setInProgress = (): AlumneActions => ({
    type :ACTIONS.QUERY_ALUMNE_REQUEST,
})

export const setAlumneSuccess = (alumne: Alumne): AlumneActions => ({
    type:ACTIONS.QUERY_ALUMNE_SUCCESS,
    alumne
})

export const setAlumneFailure = (error:string): AlumneActions => ({
    type :ACTIONS.QUERY_ALUMNE_FAILURE,
    error
})
export const setStepInProgress = (): AlumneActions => ({
    type :ACTIONS.QUERY_STEP_REQUEST,
})

export const setStepSuccess = (step: number): AlumneActions => ({
    type:ACTIONS.QUERY_STEP_SUCCESS,
    step
})

export const setStepFailure = (error:string): AlumneActions => ({
    type :ACTIONS.QUERY_STEP_FAILURE,
    error
})
export const setCentreInProgress = (): AlumneActions => ({
    type :ACTIONS.QUERY_CENTRE_REQUEST,
})

export const setCentreSuccess = (idCentre: number): AlumneActions => ({
    type:ACTIONS.QUERY_CENTRE_SUCCESS,
    idCentre
})

export const setCentreFailure = (error:string): AlumneActions => ({
    type :ACTIONS.QUERY_CENTRE_FAILURE,
    error
})

export const setEndPractiquesInProgress = (): AlumneActions => ({
    type :ACTIONS.QUERY_END_PRACTIQUES_REQUEST,
})

export const setEndPractiquesSuccess = (endPractiques: number): AlumneActions => ({
    type:ACTIONS.QUERY_END_PRACTIQUES_SUCCESS,
    endPractiques
})

export const setEndPractiquesFailure = (error:string): AlumneActions => ({
    type :ACTIONS.QUERY_END_PRACTIQUES_FAILURE,
    error
})

export const setConvocatoria = (alumne:Alumne): AlumneActions => ({
    type :ACTIONS.QUERY_SEND_CONVOCATORIA_SUCCESS,
    alumne
})

export interface AlumneRequestAction {
    type: typeof ACTIONS.QUERY_ALUMNE_REQUEST
}

export interface AlumneSuccessAction {
    type: typeof ACTIONS.QUERY_ALUMNE_SUCCESS,
    alumne : Alumne
}

export interface AlumneSendConvocatoriaAction {
    type: typeof ACTIONS.QUERY_SEND_CONVOCATORIA_SUCCESS,
    alumne : Alumne
}

export interface AlumneFailureAction {
    type: typeof ACTIONS.QUERY_ALUMNE_FAILURE,
    error : string
}

export interface StepRequestAction {
    type: typeof ACTIONS.QUERY_STEP_REQUEST
}

export interface StepSuccessAction {
    type: typeof ACTIONS.QUERY_STEP_SUCCESS,
    step : number
}

export interface StepFailureAction {
    type: typeof ACTIONS.QUERY_STEP_FAILURE,
    error : string
}

export interface EndPractiquesRequestAction {
    type: typeof ACTIONS.QUERY_END_PRACTIQUES_REQUEST
}

export interface EndPractiquesSuccessAction {
    type: typeof ACTIONS.QUERY_END_PRACTIQUES_SUCCESS,
    endPractiques : number
}

export interface EndPractiquesFailureAction {
    type: typeof ACTIONS.QUERY_END_PRACTIQUES_FAILURE,
    error : string
}

export interface CentreRequestAction {
    type: typeof ACTIONS.QUERY_CENTRE_REQUEST
}

export interface CentreSuccessAction {
    type: typeof ACTIONS.QUERY_CENTRE_SUCCESS,
    idCentre : number
}

export interface CentreFailureAction {
    type: typeof ACTIONS.QUERY_CENTRE_FAILURE,
    error : string
}


export type AlumneActions =
    AlumneRequestAction
    | AlumneSuccessAction
    | StepRequestAction
    | StepSuccessAction
    | StepFailureAction
    | CentreRequestAction
    | CentreSuccessAction
    | CentreFailureAction
    | EndPractiquesFailureAction
    | EndPractiquesRequestAction
    | EndPractiquesSuccessAction
    | AlumneSendConvocatoriaAction
    ;

export const clearAlumne = () => {
    return async (dispatch: Dispatch<UsersActions>) => {
        dispatch(setInProgress())
        try {
            dispatch(setAlumneSuccess({
                nom: '',
                id_alumne: 0,
                cognom:''
            }));
        } catch (error:any) {
            dispatch(setAlumneFailure(error))
        }
    }
}

export const updateStep = (idMatricules:number,step:number) => {
    return async (dispatch: Dispatch<UsersActions>) => {
        dispatch(setStepInProgress())
        try {
            const response = await setStep(idMatricules,step);
            if (response) {
                if (response.data) {
                    dispatch(setStepSuccess(response.data));
                }
            }
        } catch (error:any) {
            dispatch(setStepFailure(error))
        }
    }
}

export const updateEndPractiques = (idMatricules:number,endPractiques:number) => {
    return async (dispatch: Dispatch<UsersActions>) => {
        dispatch(setEndPractiquesInProgress())
        try {
            const response = await setEndPractiques(idMatricules,endPractiques);
            if (response) {
                if (response.data) {
                    dispatch(setEndPractiquesSuccess(response.data));
                }
            }
        } catch (error:any) {
            dispatch(setCentreFailure(error))
        }
    }
}

export const updateIdCentre = (idMatricules:number,idCentre:number) => {
    return async (dispatch: Dispatch<UsersActions>) => {
        dispatch(setCentreInProgress())
        try {
            const response = await setCentre(idMatricules,idCentre);
            if (response) {
                if (response.data) {
                    dispatch(setCentreSuccess(response.data));
                }
            }
        } catch (error:any) {
            dispatch(setCentreFailure(error))
        }
    }
}

export const sendConvocatoria = (idMatricules:number) => {
    return async (dispatch: Dispatch<UsersActions>) => {
        dispatch(setInProgress())
        try {
            const response = await updateConvocatoria(idMatricules);
            if (response) {
                if (response.data) {
                    dispatch(setConvocatoria(response.data));
                }
            }
        } catch (error:any) {
            dispatch(setAlumneFailure(error))
        }
    }
}



export const retrieveAlumne = () => {
    return async (dispatch: Dispatch<AlumneActions>) => {
        dispatch(setInProgress())
        try {
            let isEmpty = true;
            const response = await findAlumne();
            if (response) {
                if (response.data) {
                    isEmpty = false;
                    dispatch(setAlumneSuccess(response.data));
                }
            }
            if(isEmpty){
                dispatch(setAlumneFailure('isEmpty'))
            }
        } catch (error:any) {
            dispatch(setAlumneFailure(error))
        }
    }
}

const AlumneReducer = (state = InitialState, action: AlumneActions):
    { inProgress: boolean; error: string; alumne: {},step:number , idCentre:number, endPractiques: number} => {
    switch (action.type) {
        case ACTIONS.QUERY_ALUMNE_REQUEST:
        case ACTIONS.QUERY_STEP_REQUEST:
        case ACTIONS.QUERY_CENTRE_REQUEST:
        case ACTIONS.QUERY_END_PRACTIQUES_REQUEST:
            return {
                ...state,
                inProgress : true,
                error: ''
            };
        case ACTIONS.QUERY_ALUMNE_SUCCESS:
            if ("alumne" in action) {
                // @ts-ignore
                state.step = action.alumne.step ?  parseInt(action.alumne.step): 0;
            }
            return {
                ...state,
                inProgress : false,
                alumne: "alumne" in action ? action.alumne : {},
                step: "step" in action ? state.step : 0,

            };
        case ACTIONS.QUERY_SEND_CONVOCATORIA_SUCCESS:
            return {
                ...state,
                inProgress : false,
                alumne: "alumne" in action ? action.alumne : {},
            };
        case ACTIONS.QUERY_STEP_SUCCESS:
            if ("step" in action) {
                state.alumne.step = action.step;
            }
            return {
                ...state,
                inProgress : false,
                step: "step" in action ? action.step : 0,
                alumne: state.alumne

            };
        case ACTIONS.QUERY_END_PRACTIQUES_SUCCESS:
            if ("endPractiques" in action) {
                state.alumne.end_practiques = action.endPractiques;
            }
            return {
                ...state,
                inProgress : false,
                endPractiques: "endPractiques" in action ? action.endPractiques : 0,
                alumne: state.alumne

            };
        case ACTIONS.QUERY_CENTRE_SUCCESS:
            if ("idCentre" in action) {
                state.alumne.id_entitat = action.idCentre;
            }
            return {
                ...state,
                inProgress : false,
                idCentre: "idCentre" in action ? action.idCentre : 0,
                alumne: state.alumne

            };
        case ACTIONS.QUERY_CENTRE_FAILURE:
        case ACTIONS.QUERY_ALUMNE_FAILURE:
        case ACTIONS.QUERY_STEP_FAILURE:
        case ACTIONS.QUERY_END_PRACTIQUES_FAILURE:
            return {
                ...state,
                inProgress : false,
                error: "error" in action ? action.error : ''
            };
        default:
            return state;
    }
};

export {AlumneReducer};
