import { createSelector } from 'reselect'
import {AppState} from "../store/store";
import {Dispatch} from "redux";
import {Entity} from "../../types/entity";
import {addNewEntity, changeEntity, getAllEntities, getEntityById} from "../../services/entitats";

export const ACTIONS = {
    QUERY_ENTITIES_REQUEST: 'QUERY_ENTITIES_REQUEST',
    QUERY_ENTITIES_SUCCESS: 'QUERY_ENTITIES_SUCCESS',
    QUERY_ENTITIES_FAILURE: 'QUERY_ENTITIES_FAILURE',
    QUERY_ADDENTITY_REQUEST: 'QUERY_ADDENTITY_REQUEST',
    QUERY_ADDENTITY_SUCCESS: 'QUERY_ADDENTITY_SUCCESS',
    QUERY_ADDENTITY_FAILURE: 'QUERY_ADDENTITY_FAILURE',
    QUERY_GETENTITY_REQUEST: 'QUERY_GETENTITY_REQUEST',
    QUERY_GETENTITY_SUCCESS: 'QUERY_GETENTITY_SUCCESS',
    QUERY_GETENTITY_FAILURE: 'QUERY_GETENTITY_FAILURE',
    QUERY_UPDATEENTITY_REQUEST: 'QUERY_UPDATEENTITY_REQUEST',
    QUERY_UPDATEENTITY_SUCCESS: 'QUERY_UPDATEENTITY_SUCCESS',
    QUERY_UPDATEENTITY_FAILURE: 'QUERY_UPDATEENTITY_FAILURE',
    QUERY_ENTITIES_INIT: 'QUERY_ENTITIES_INIT',
};

const InitialState = {
    entities : [] as Entity[],
    entity: {} as Entity,
    inProgress : false,
    newEntity : false,
    error: '',
    status: 'idle'
}

const getSlice = (state: AppState) => state.entities;

export const getEntities = createSelector(
    getSlice,
    ({entities}) => entities,
);

export const getError = createSelector(
    getSlice,
    ({error}) => error,
);

export const getNewEntity = createSelector(
    getSlice,
    ({newEntity}) => newEntity,
);


export const getEntityByUser = createSelector(
    getSlice,
    ({entity}) => entity,
);

export const getStatusEntitats = createSelector(
    getSlice,
    ({status}) => status,
);


const initialState = (dispatch: Dispatch<EntitiesActions>) => {
    setTimeout(() => {
        dispatch(setInit());
    }, 1000);
}

export const setInit = (): EntitiesActions => ({
    type :ACTIONS.QUERY_ENTITIES_INIT,
})


export const getIsInProgress = createSelector(
    getSlice,
    ({inProgress}) => inProgress,
);

export const setInProgress = (): EntitiesActions => ({
    type :ACTIONS.QUERY_ENTITIES_REQUEST,
})

export const setEntitatsSuccess = (entities: Entity[]): EntitiesActions => ({
    type:ACTIONS.QUERY_ENTITIES_SUCCESS,
    entities
})

export const setEntitatsFailure = (error:string): EntitiesActions => ({
    type :ACTIONS.QUERY_ENTITIES_FAILURE,
    error
})


export interface EntitiesRequestAction {
    type: typeof ACTIONS.QUERY_ENTITIES_REQUEST
}

export interface EntitiesSuccessAction {
    type: typeof ACTIONS.QUERY_ENTITIES_SUCCESS,
    entities : Entity[]
}

export interface EntitiesFailureAction {
    type: typeof ACTIONS.QUERY_ENTITIES_FAILURE,
    error : string
}

export const addEntityInProgress = (): EntitiesActions => ({
    type :ACTIONS.QUERY_ADDENTITY_REQUEST,
})

export const addEntitySuccess = (entity: Entity): EntitiesActions => ({
    type:ACTIONS.QUERY_ADDENTITY_SUCCESS,
    entity
})

export const addEntityFailure = (error:string): EntitiesActions => ({
    type :ACTIONS.QUERY_ADDENTITY_FAILURE,
    error
})

export const updateEntityInProgress = (): EntitiesActions => ({
    type :ACTIONS.QUERY_UPDATEENTITY_REQUEST,
})

export const updateEntitySuccess = (entity: Entity): EntitiesActions => ({
    type:ACTIONS.QUERY_UPDATEENTITY_SUCCESS,
    entity
})

export const updateEntityFailure = (error:string): EntitiesActions => ({
    type :ACTIONS.QUERY_UPDATEENTITY_FAILURE,
    error
})


export const getEntityInProgress = (): EntitiesActions => ({
    type :ACTIONS.QUERY_GETENTITY_REQUEST,
})

export const getEntitySuccess = (entity: Entity): EntitiesActions => ({
    type:ACTIONS.QUERY_GETENTITY_SUCCESS,
    entity
})

export const getEntityFailure = (error:string): EntitiesActions => ({
    type :ACTIONS.QUERY_GETENTITY_FAILURE,
    error
})


export interface EntityRequestAction {
    type: typeof ACTIONS.QUERY_ADDENTITY_REQUEST
}

export interface EntitySuccessAction {
    type: typeof ACTIONS.QUERY_ADDENTITY_SUCCESS,
    entity: Entity
}

export interface EntityFailureAction {
    type: typeof ACTIONS.QUERY_ADDENTITY_FAILURE,
    error : string
}

export interface GetEntityRequestAction {
    type: typeof ACTIONS.QUERY_GETENTITY_REQUEST
}

export interface GetEntitySuccessAction {
    type: typeof ACTIONS.QUERY_GETENTITY_SUCCESS,
    entity: Entity
}

export interface GetEntityFailureAction {
    type: typeof ACTIONS.QUERY_GETENTITY_FAILURE,
    error : string
}

export interface UpdateEntityRequestAction {
    type: typeof ACTIONS.QUERY_UPDATEENTITY_REQUEST
}

export interface UpdateEntitySuccessAction {
    type: typeof ACTIONS.QUERY_UPDATEENTITY_SUCCESS,
    entity: Entity
}

export interface UpdateEntityFailureAction {
    type: typeof ACTIONS.QUERY_UPDATEENTITY_FAILURE,
    error : string
}

export type EntitiesActions =
    EntitiesRequestAction
    | EntitiesSuccessAction
    | EntitiesFailureAction
    | EntityRequestAction
    | EntitySuccessAction
    | EntityFailureAction
    | GetEntityFailureAction
    | GetEntitySuccessAction
    | GetEntityRequestAction
    | UpdateEntityFailureAction
    | UpdateEntitySuccessAction
    | UpdateEntityRequestAction
    ;


export const retrieveEntities = () => {
    return async (dispatch: Dispatch<EntitiesActions>) => {
        dispatch(setInProgress())
        try {
            const response = await getAllEntities();
            if (response) {
                if (response.data) {
                    dispatch(setEntitatsSuccess(response.data));
                }
            } else {
                dispatch(setEntitatsFailure('isEmpty'))
            }
        } catch (error:any) {
            dispatch(setEntitatsFailure(error))
        }
    }
}

export const addEntity = (entity:Entity) => {
    return async (dispatch: Dispatch<EntitiesActions>) => {
        dispatch(addEntityInProgress())
        try {
            const response = await addNewEntity(entity);
            if (response) {
                if (response.data.status === 'OK') {
                    dispatch(addEntitySuccess(entity));
                } else {
                    dispatch(addEntityFailure(response.data.message))
                }
            } else {
                dispatch(addEntityFailure('isEmpty'))
            }
            initialState(dispatch);
        } catch (error:any) {
            console.log(error);
            dispatch(addEntityFailure('error intentant afegir el teu centre'))
            initialState(dispatch);
        }
    }
}


export const updateEntity = (entity:Entity) => {
    console.log('update', entity);
    return async (dispatch: Dispatch<EntitiesActions>) => {
        dispatch(setInProgress())
        try {
            const response = await changeEntity(entity);
            if (response) {
                if (response.data.status === 'OK') {
                    dispatch(updateEntitySuccess(entity));
                    initialState(dispatch);
                } else {
                    dispatch(updateEntityFailure(response.data.message))
                }
            } else {
                dispatch(updateEntityFailure('isEmpty'))
            }
        } catch (error:any) {
            dispatch(addEntityFailure(error))
        }
    }
}

export const getEntity = (id:number) => {
    return async (dispatch: Dispatch<EntitiesActions>) => {
        dispatch(getEntityInProgress())
        try {
            if(id){
                const response = await getEntityById(id);
                if (response) {
                    if (response.data) {
                        dispatch(getEntitySuccess(response.data));
                    }
                }
            }
        } catch (error:any) {
            dispatch(getEntityFailure(error))
        }
    }
}

const EntitatReducer = (state = InitialState, action: EntitiesActions):
    { inProgress: boolean; error: string; entities: Entity[], entity: {},newEntity:boolean, status: string } => {
    switch (action.type) {
        case ACTIONS.QUERY_ENTITIES_REQUEST:
        case ACTIONS.QUERY_ADDENTITY_REQUEST:
        case ACTIONS.QUERY_GETENTITY_REQUEST:
            return {
                ...state,
                inProgress : true,
                status: 'inProgress',
                error: ''
            };
        case ACTIONS.QUERY_ENTITIES_SUCCESS:
            return {
                ...state,
                inProgress : false,
                status: 'completed',
                entities: "entities" in action ? action.entities : []
            };
        case ACTIONS.QUERY_ADDENTITY_SUCCESS:
            if("entity" in action && action.entity){
                const newEntity:Entity = {cif: "", rao_social: ""};
                newEntity.rao_social = action.entity.rao_social;
                newEntity.cif = action.entity.cif;
                state.entities.push(newEntity);
            }
            return {
                ...state,
                inProgress : false,
                newEntity: true,
                entity: "entity" in action ? action.entity : {},
                entities: state.entities,
                status: 'completed',
                error: ''

            };
        case ACTIONS.QUERY_UPDATEENTITY_SUCCESS:
            if ("entity" in action && action.entity) {
                const updatedEntity = action.entity;

                // Encuentra el índice de la entidad a actualizar
                const entityIndex = state.entities.findIndex(
                    (e) => e.id === updatedEntity.id
                );

                if (entityIndex > -1) {
                    // Copia el arreglo de entidades actual
                    const updatedEntities = [...state.entities];

                    // Actualiza la entidad en el índice encontrado
                    updatedEntities[entityIndex] = {
                        ...updatedEntities[entityIndex],  // Mantiene las propiedades existentes
                        ...updatedEntity,  // Aplica las actualizaciones del `action.entity`
                    };

                    return {
                        ...state,
                        inProgress: false,
                        entity: updatedEntity,
                        entities: updatedEntities,
                        status: 'completed',
                    };
                }
            }

            // Si no se encuentra la entidad para actualizar o no hay acción válida
            return {
                ...state,
                inProgress: false,
                newEntity: false,
                status: 'completed',
            };
        case ACTIONS.QUERY_GETENTITY_SUCCESS:
            return {
                ...state,
                inProgress : false,
                entity: "entity" in action ? action.entity : {},
                status: 'completed',
            };
        case ACTIONS.QUERY_ENTITIES_INIT:
            return {
                ...state,
                inProgress : false,
                newEntity : false,
                status: 'idle',
            }
        case ACTIONS.QUERY_ENTITIES_FAILURE:
        case ACTIONS.QUERY_ADDENTITY_FAILURE:
        case ACTIONS.QUERY_GETENTITY_FAILURE:
            // console.log('acton', action.error)
            return {
                ...state,
                inProgress : false,
                error: "error" in action ? action.error : 'ERROR',
                status: 'error',
            };
        default:
            return state;
    }
};



export {EntitatReducer};
