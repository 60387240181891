import {FileAlumne} from "../types/alumne";
import {api} from "./api";

export async function addFileMemoria(fileAlumne: FileAlumne, idMatricula:number) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);
        data['file'] = fileAlumne.file;
        data['file_name'] = fileAlumne.file_name;
        data['idMatricula'] = idMatricula;
        return await api.post<number>("/set_memoria_practiques", data);
    }
}

export async function addFileProjecte(fileAlumne: FileAlumne,idMatricula:number) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);
        data['file'] = fileAlumne.file;
        data['file_name'] = fileAlumne.file_name;
        data['idMatricula'] = idMatricula;
        return await api.post<number>("/set_projecte_practiques", data);
    }
}

export async function getFileProjecte(fileID: number,idMatricula:number) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);
        data['fileId'] = fileID;
        data['idMatricula'] = idMatricula;
        return await api.post<number>("/get_projecte_practiques", data);
    }
}

export async function getFileMemoria(fileID: number, idMatricula:number) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);
        data['fileId'] = fileID;
        data['idMatricula'] = idMatricula;
        return await api.post<number>("/get_memoria_practiques", data);
    }
}

export async function deleteFileProjecte(fileID: number,idMatricula:number) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);
        data['fileId'] = fileID;
        data['idMatricula'] = idMatricula;
        return await api.post<boolean>("/delete_projecte_practiques", data);
    }
}

export async function deleteFileMemoria(fileID: number, idMatricula:number) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);
        data['fileId'] = fileID;
        data['idMatricula'] = idMatricula;
        return await api.post<boolean>("/delete_memoria_practiques", data);
    }
}