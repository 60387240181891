import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Entity} from "../../types/entity";
import Input from "../../components/Input";
import Select from "../../components/Select";
import {TutorPractiques} from "../../types/tutorPractiques";
import {useHistory} from "react-router-dom";
import Modal from "../../components/Modal";
import SubHeader from "../../components/SubHeader";
import ButtonDownloadPdf from "../../components/ButtonDownloadPdf";
import SpinnerComponent from "../../components/SpinnerComponent";

const Step2: React.FC = ({
                             alumne,
                             entity,
                             provincies,
                             updateTutorPractiques,
                             retrieveTutorPractiques,
                             tutorPractiquesSaved,
                             addFilePractiques,
                             addFileTutor,
                             removeFilePractiques,
                             setStep,
                             errorTutor,
                             statusTutor,
                             inProgressAlumne,
                             getEntity,
                             updateEntity,
                         }: any) => {

    const [entitySelected, setEntitySelected] = useState<Entity>({
        'cif': '',
        'email_responsable': '',
        'rao_social': '',
        'responsable_legal': '',
        'id': 0,
    })
    const [tutorPractiques, setTutorPractiques] = useState<TutorPractiques>({
        idMatricula: "",
        p_fitxa_files: 0,
        p_titol_files: 0,
        p_tutor_carnet: "",
        p_tutor_carnet_expedicio: 0,
        p_tutor_carnet_emes: 0,
        p_tutor_cognoms: "",
        p_tutor_email: "",
        p_tutor_nom: "",
        p_tutor_telefon: "",
        p_fitxa_files_name: "",
        p_fitxa_files_url: "",
        p_titol_files_name: "",
        p_titol_files_url: ""
    })
    const [openModal, setOpenModal] = useState(false);
    const [documentUpload, setDocumentUpload] = useState(false);
    const history = useHistory();
    const [message, setMessage] = useState('');
    const [messageTutor, setMessageTutor] = useState('');
    const [messageAcord, setMessageAcord] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingAlumne, setLoadingAlumne] = useState(false);
    const locations = [
        { id: 1, name: 'a Catalunya' },
        { id: 3, name: 'a fora de Catalunya' },
      ];

    useEffect(() => {
        if (entitySelected && entity.cif !== entitySelected.cif) {
            console.log(entity);
            setEntitySelected(entity)
        }
    }, [entity]);

    useEffect(() =>{
        setLoadingAlumne(inProgressAlumne);
    }, [inProgressAlumne])

    useEffect(() => {
        setTutorPractiques(tutorPractiquesSaved);
        if(statusTutor === 'loading'){
            setLoading(true)
        }
        if(statusTutor === 'completed' && documentUpload){
            setOpenModal(true)
            setMessage('Tot ha anat bé.')
            setDocumentUpload(false);
        }
        if(statusTutor === 'error'){
            setOpenModal(true)
        }
        if(errorTutor){
            const error = JSON.parse(errorTutor);
            setMessage(error.message);
        }
        if(statusTutor === 'idle'){
            // setTimeout(() => {
            //     setLoading(false);
            // }, 3000);
            setLoading(false)
        }
    }, [errorTutor, statusTutor]);

    useEffect(() => {
        setTutorPractiques((prevState) => ({
            ...prevState,
            ['idMatricula']: alumne.idMatricules,
        }))
        retrieveTutorPractiques(alumne.idMatricules);
        getEntity(alumne.id_entitat);
    }, [alumne])

    const tutorChange = (name: any, event: React.ChangeEvent<HTMLInputElement> | any): void => {
        event.preventDefault();
        const value = event.target.value;
        console.log(name, value);
        setTutorPractiques((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    };
    const entityChange = (name: any, event: React.ChangeEvent<HTMLInputElement> | any): void => {
        event.preventDefault();
        const value = event.target.value;
        setEntitySelected((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    };

    const handleTutor = () => {
        let pas3 = true;
        setMessage('')
        console.log(tutorPractiques)
        if (!tutorPractiques.p_tutor_nom) {
            pas3 = false;
            setMessage('Falta el nom del tutor');
            setOpenModal(true);
        }
        if (!tutorPractiques.p_tutor_cognoms) {
            setMessage('Falta el cognoms del tutor');
            setOpenModal(true);
            pas3 = false;
        }
        if (!tutorPractiques.p_tutor_telefon) {
            setMessage('Falta el telefón del tutor');
            setOpenModal(true);
            pas3 = false;
        }
        if (!tutorPractiques.p_tutor_email) {
            setMessage('Falta el email del tutor');
            setOpenModal(true);
            pas3 = false;
        }
        if (!tutorPractiques.p_tutor_carnet) {
            setMessage("Falta el número del carnet del tutor");
            setOpenModal(true);
            pas3 = false;
        }
        if (!tutorPractiques.p_tutor_carnet_expedicio) {
            setMessage("Falta el carnet d'expedicio del tutor");
            setOpenModal(true);
            pas3 = false;
        }
        if (!tutorPractiques.p_titol_files) {
            setMessage('Falta el titol del tutor');
            setOpenModal(true);
            pas3 = false;
        }
        if (!tutorPractiques.p_fitxa_files) {
            setMessage('Falta el fitxer acord de practiques');
            setOpenModal(true);
            pas3 = false;
        }
        if (pas3) {
            console.log(tutorPractiques);
            updateTutorPractiques(tutorPractiques);
            setStep(alumne.idMatricules, parseInt(alumne.step) + 1)
        }

    }

    const handleRemoveFile = (type: string, event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        removeFilePractiques(tutorPractiques, type)
        if (type === 'acord') {
            setTutorPractiques((prevState) => ({
                ...prevState,
                ['p_fitxa_files']: 0,
                ['p_fitxa_files_url']: '',
                ['p_fitxa_files_name']: '',
            }))
        }
        if (type === 'tutor') {
            setTutorPractiques((prevState) => ({
                ...prevState,
                ['p_titol_files']: 0,
                ['p_titol_files_url']: '',
                ['p_titol_files_name']: '',
            }))
        }
    }

    const onFileTutorChange = (e: React.ChangeEvent<HTMLInputElement>, type: string): void => {
        setDocumentUpload(true);
        let files = e.target.files;
        let fileReader = new FileReader();
        if (files) {
            fileReader.readAsDataURL(files[0]);
            fileReader.onload = (event) => {
                if (fileReader.result) {
                    if (type === 'tutor') {
                        addFileTutor({
                            file_name: files![0].name,
                            file: fileReader.result,
                        }, alumne.idMatricules);
                    }
                    if (type === 'acord') {
                        addFilePractiques({
                            file_name: files![0].name,
                            file: fileReader.result,
                        }, alumne.idMatricules);
                    }
                }

            }
        }
    }

    const handleUpdateCentrePractiques = (e:React.FormEvent<HTMLFormElement>) =>{
        e.preventDefault()
        console.log(entitySelected);
        updateEntity(entitySelected);
        setMessage('Nom responsable o email canviat. Gràcies');
        setOpenModal(true);
    }
    return (
        <>
            <div className="flex flex-col items-center justify-between min-h-screen bg-white">
                <Header/>
                <SubHeader title="2. ACORD DE PRÀCTIQUES"/>
                <p className="flex flex-col px-8 py-6 mt-4 text-left bg-white w-4/5 	">
                    L’Acord de pràctiques és un document que regula les pràctiques del curs de monitoratge o de direcció
                    d’activitats d’educació en el lleure infantil i juvenil que realitzarà l’ alumnat en el lloc i
                    període establerts en l’apartat “Dades del centre i període de pràctiques”.
                    Aquest acord és individual per a cada persona .
                </p>
                <div className="flex flex-col px-8 py-6 mt-4 text-left w-4/5 	">
                    {entitySelected &&
                    <>
                        <div className="border-solid border-t-2 p-4  border-orange-600 shadow-lg">
                            <h1 className="text-center text-xl font-bold mb-4">Centre de pràctiques</h1>
                            <form onSubmit={(e)=>handleUpdateCentrePractiques(e)} autoComplete="off">
                                <Input title="Nom" value={entitySelected.rao_social} disabled/>
                                <Input title="CIF" value={entitySelected.cif} disabled/>
                                <Input title="Correu electrònic" 
                                       value={entitySelected.email_responsable}
                                       onChange={e => entityChange('email_responsable', e)}/>
                                <Input title="Nom responsable legal" 
                                       value={entitySelected.responsable_legal}
                                       onChange={e => entityChange('responsable_legal', e)}/>

                                <button type="submit" className="flex px-6 py-2 mt-4 mx-auto text-white bg-orange-500 rounded-lg hover:bg-orange-900"> Canviar dades</button>
                            </form>
                        </div>
                    </>
                    }
                </div>
                <form className="flex flex-col px-8 py-6 mt-4 text-left bg-white w-4/5">
                    <div className="border-solid border-t-2 p-4 border-orange-600 shadow-lg">
                        <h1 className="text-center text-xl font-bold mb-2">Tutoria de pràctiques</h1>
                        <p className="text-center text-xs font-bold mb-4">
                            Omple totes les dades de la persona amb títol oficial de direcció de lleure o ROPELL
                            que et supervisarà les pràctiques:
                        </p>
                        <Input title="Nom tutor/a:"
                               placeholder="Nom"
                               name="p_tutor_nom"
                               onChange={e => tutorChange('p_tutor_nom', e)}
                               value={tutorPractiques.p_tutor_nom}
                               required={true}
                        />
                        <Input title="Cognoms tutor/a:"
                               placeholder="Cognoms"
                               name="p_tutor_cognoms"
                               onChange={e => tutorChange('p_tutor_cognoms', e)}
                               value={tutorPractiques.p_tutor_cognoms}
                               required={true}
                        />
                         <Input title="Telèfon tutor/a:"
                               name="p_tutor_telefon"
                               placeholder="123456789"
                               type="tel"
                               onChange={e => tutorChange('p_tutor_telefon', e)}
                               value={tutorPractiques.p_tutor_telefon}
                               required={true}
                        />
                        <Input title="Correu electrònic tutor/a:"
                               name="p_tutor_email"
                               placeholder="email@email.com"
                               type="email"
                               onChange={e => tutorChange('p_tutor_email', e)}
                               value={tutorPractiques.p_tutor_email}
                               required={true}
                        />
                        <Input title="Títol director tutor/a:" 
                               name="p_tutor_carnet"
                               onChange={e => tutorChange('p_tutor_carnet', e)}
                               value={tutorPractiques.p_tutor_carnet}
                               placeholder="00000000/X"
                               required={true}
                        />
                        <Select title="Carnet emés tutor/a: "
                                name="p_tutor_carnet_emes"
                                list={locations}
                                value={tutorPractiques.p_tutor_carnet_emes}
                                onChange={e => {
                                    tutorChange('p_tutor_carnet_emes ', e);
                                }}
                        />
                        <Select title="Carnet expedició tutor/a: "
                                name="p_tutor_carnet_expedicio"
                                list={provincies}
                                value={tutorPractiques.p_tutor_carnet_expedicio}
                                onChange={e => {
                                    tutorChange('p_tutor_carnet_expedicio', e);
                                }}
                        />

                        {
                            !tutorPractiques.p_titol_files_url &&
                            <Input title="Penja el títol de director/a.*:"
                                   name="p_titol_files"
                                   type={'file'}
                                   onChange={e => {
                                       onFileTutorChange(e, 'tutor')
                                   }}
                                   value={tutorPractiques.p_titol_files_name}
                                   required={true}/>
                        }

                        {
                            process.env.REACT_APP_HELIOS_URL && tutorPractiques.p_titol_files_url &&
                            <div className="w-full ">
                                <div className=" md:grid md:grid-cols-6 md:items-center mb-6">
                                    <div className="col-span-2">
                                        <label
                                            className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                                            Penja el títol de director/a:*:
                                        </label>
                                    </div>
                                    <div className="flex col-span-3 bg-gray-200 ">
                                        <p className="p-1 w-full flex flex-row space-between justify-center">
                                            <a className="text-orange-400"
                                               href={process.env.REACT_APP_HELIOS_URL + tutorPractiques.p_titol_files_url}
                                               target="_blank">
                                                {
                                                    process.env.REACT_APP_HELIOS_URL + tutorPractiques.p_titol_files_name
                                                }
                                            </a>
                                            <button
                                                className="cursor-pointer
                                                         relative
                                                         ml-auto
                                                         text-gray-400
                                                         hover:text-gray-600
                                                         transition duration-150
                                                         ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleRemoveFile('tutor', e)
                                                }}
                                                aria-label="close modal" role="button">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className="icon icon-tabler icon-tabler-x"
                                                     width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5"
                                                     stroke="currentColor" fill="none" strokeLinecap="round"
                                                     strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z"/>
                                                    <line x1="18" y1="6" x2="6" y2="18"/>
                                                    <line x1="6" y1="6" x2="18" y2="18"/>
                                                </svg>
                                            </button>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                    <div className="flex flex-col px-8 py-6 mt-4 text-left bg-white w-4/5 	">
                        <p className="text-xs">
                            * En cas que el teu tutor/a de pràctiques tingui un títol de director
                            emes fora de Catalunya o tingui una titulació equivalent a aquesta, s’ha d’inscriure al ROPELL,
                            on li donaran un número de registre que has de posar en la casella “Títol director tutor/A”.
                           
                            Enllaç ROPELL  (Registre Oficial de Professionals de l’Educació en el Lleure de Catalunya):
                            <a className="text-orange-400 ml-4"
                               target="_blank"
                               href="https://jovecat.gencat.cat/ca/temes/vacances_i_estades/activitats_educacio_lleure_Recursos_entitats_empreses/Ropell/index.html">
                                https://dretssocials.gencat.cat/ca/tramits/tramits-temes/Registre-Oficial-de-Professionals-de-lEducacio-en-el-Lleure-de-Catalunya
                            </a>
                        </p>
                    </div>

                    <div className="border-solid border-t-2 p-4 border-orange-600 shadow-lg">
                        <h1 className="text-center text-xl font-bold mb-2">Acord de pràctiques</h1>
                        <p className="text-center text-xl mb-4 ">
                            Descarrega’t l’acord de pràctiques:
                            <ButtonDownloadPdf
                                title={'Descarregar'}
                                nameDocument={'Model-Acord-realitzacio-practiques.pdf'}
                            />
                        </p>
                        {
                            !tutorPractiques.p_fitxa_files_url &&
                            <Input title="Penja l’acord de pràctiques**:"
                                   name="p_fitxa_files"
                                   type={'file'}
                                   value={tutorPractiques.p_fitxa_files_name}
                                   onChange={e => onFileTutorChange(e, 'acord')}
                            />
                        }

                        {
                            process.env.REACT_APP_HELIOS_URL && tutorPractiques.p_fitxa_files_url &&
                            <div className="w-full ">
                                <div className=" md:grid md:grid-cols-6 md:items-center mb-6">
                                    <div className="col-span-2">
                                        <label
                                            className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                                           Document segellat i signat per l’Escola **:
                                        </label>
                                    </div>
                                    <div className="flex col-span-3 bg-gray-200 ">
                                        <p className="p-1 w-full flex flex-row space-between justify-center">
                                            <a className="text-orange-400"
                                               href={process.env.REACT_APP_HELIOS_URL + tutorPractiques.p_fitxa_files_url}
                                               target="_blank">
                                                {
                                                    process.env.REACT_APP_HELIOS_URL + tutorPractiques.p_fitxa_files_name
                                                }
                                            </a>
                                            <button
                                                className="cursor-pointer
                                                         relative
                                                         ml-auto
                                                         text-gray-400
                                                         hover:text-gray-600
                                                         transition duration-150
                                                         ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleRemoveFile('acord', e)
                                                }}
                                                aria-label="close modal" role="button">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className="icon icon-tabler icon-tabler-x"
                                                     width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5"
                                                     stroke="currentColor" fill="none" strokeLinecap="round"
                                                     strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z"/>
                                                    <line x1="18" y1="6" x2="6" y2="18"/>
                                                    <line x1="6" y1="6" x2="18" y2="18"/>
                                                </svg>
                                            </button>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="flex w-full justify-center align-center self-center">
                            <div className="text-center text-xl p-4 w-2/4 ">
                                <p>
                                    Instruccions:
                                </p>
                                <ul className="list-decimal text-center">
                                    <li>
                                        Descarregar't l'acord de pràctiques
                                    </li>
                                    <li>
                                        Omplir els camps que es demanen
                                    </li>
                                    <li>
                                        Signar per l’alumne i el director/a de pràctiques
                                    </li>
                                    <li>
                                        Segellar amb el segell del centre de pràctiques
                                    </li>
                                    <li>
                                        Penjar el document en PDF
                                    </li>
                                </ul>

                            </div>
                        </div>


                    </div>
                </form>
                <Modal open={openModal} setOpen={setOpenModal}>
                    <p className="mx-auto">
                        {message}
                    </p>
                </Modal>
                <Footer onClickNext={handleTutor}/>

            </div>
            <SpinnerComponent open={loading} />
            <SpinnerComponent open={loadingAlumne} />
        </>
    )

}

export default withTranslation('common')(Step2);
