import React from 'react';
import Modal from "../Modal";

const SpinnerComponent: React.FC = ({ open }: any) => {
  return (
      <Modal open={open} closeButton={false}>
          <p className="mx-auto">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  style={{ margin: 'auto', background: '#ffffff', display: 'block' }}
                  width="200px"
                  height="200px"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
              >
                  <g transform="translate(20 50)">
                      <circle cx="0" cy="0" r="6" fill="#1e1e1e" transform="scale(1.02173 1)">
                          <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.1s"
                              repeatCount="indefinite"
                              dur="0.6s"
                              keyTimes="0;0.2;1"
                              values="1.5 1.5;1 1;1.5 1.5"
                          />
                      </circle>
                  </g>
                  <g transform="translate(40 50)">
                      <circle cx="0" cy="0" r="6" fill="#1e1e1e" transform="scale(1.15184 1)">
                          <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.2s"
                              repeatCount="indefinite"
                              dur="0.6s"
                              keyTimes="0;0.2;1"
                              values="1.5 1.5;1 1;1.5 1.5"
                          />
                      </circle>
                  </g>
                  <g transform="translate(60 50)">
                      <circle cx="0" cy="0" r="6" fill="#1e1e1e" transform="scale(1.25195 1)">
                          <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.3s"
                              repeatCount="indefinite"
                              dur="0.6s"
                              keyTimes="0;0.2;1"
                              values="1.5 1.5;1 1;1.5 1.5"
                          />
                      </circle>
                  </g>
              </svg>
          </p>
      </Modal>
  );
};

export default SpinnerComponent;
