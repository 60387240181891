import React from "react";
import {withTranslation} from "react-i18next";
import {Provincia} from "../../types/provincies";

const Select: React.FC = ({title,onChange,name,list , value}: any) => {

    return (
        <>
            <div className="w-full ">
                <div className=" md:grid md:grid-cols-6 md:items-center mb-6">
                    <div className="col-span-2">
                        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                               htmlFor={name}>
                            {title}
                        </label>
                    </div>
                    <div className="col-span-3">
                        <select
                            className="form-select bg-gray-200  bg-clip-padding bg-no-repeat block appearance-none border-1 border-gray-200
                                rounded w-full py-2 px-4 text-gray-700 leading-tight
                                focus:ring-1 focus:ring-orange-600
                                focus:outline-none focus:bg-white focus:border-orange-500"
                            aria-label="Default select example"
                            name={name}
                            value={value}
                            onChange={onChange}
                        >
                            {
                                list &&  list.map((post:any, index:number) => (
                                    <option key={index} value={post.id}>
                                        {post.name}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
        </>
    )

}

export default withTranslation('common')(Select);
