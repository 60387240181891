import {api} from "../services/api";
import {FileAlumne} from "../types/alumne";
import {TutorPractiques} from "../types/tutorPractiques";

export async function addFileTitolDirector(fileAlumne: FileAlumne, idMatricula:number) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);
        data['file'] = fileAlumne.file;
        data['file_name'] = fileAlumne.file_name;
        data['idMatricula'] = idMatricula;
        return await api.post<number>("/set_titol_director", data);
    }
}

export async function addFileAcordPractiques(fileAlumne: FileAlumne,idMatricula:number) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);
        data['file'] = fileAlumne.file;
        data['file_name'] = fileAlumne.file_name;
        data['idMatricula'] = idMatricula;
        return await api.post<number>("/set_acord_practiques", data);
    }
}

export async function updateTutorPractiques(tutorPractiques: TutorPractiques) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);

        data['idMatricula'] = tutorPractiques.idMatricula;
        data['p_tutor_nom'] = tutorPractiques.p_tutor_nom;
        data['p_tutor_cognoms'] = tutorPractiques.p_tutor_cognoms;
        data['p_tutor_email'] = tutorPractiques.p_tutor_email;
        data['p_tutor_carnet'] = tutorPractiques.p_tutor_carnet;
        data['p_tutor_carnet_expedicio'] = tutorPractiques.p_tutor_carnet_expedicio;
        data['p_tutor_carnet_emes'] = tutorPractiques.p_tutor_carnet_emes;
        data['p_tutor_telefon'] = tutorPractiques.p_tutor_telefon;
        data['p_titol_files'] = tutorPractiques.p_titol_files;
        data['p_fitxa_files'] = tutorPractiques.p_fitxa_files;

        return await api.post<number>("/set_tutor_matricula", data);
    }
}

export async function removeFileTutorPractiques(tutorPractiques: TutorPractiques) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);

        data['idMatricula'] = tutorPractiques.idMatricula;
        data['p_titol_files'] = tutorPractiques.p_titol_files;

        return await api.post<boolean>("/delete_titol_director", data);
    }
}

export async function removeFileAcordPractiques(tutorPractiques: TutorPractiques) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);

        data['idMatricula'] = tutorPractiques.idMatricula;
        data['p_fitxa_files'] = tutorPractiques.p_fitxa_files;

        return await api.post<boolean>("/delete_acord_practiques", data);
    }
}


export async function getTutorPractiquesByMatriculaId(idMatricula: string) {
    if(localStorage.getItem('user')){
        const data = JSON.parse(localStorage.getItem('user') as string);
        data['idMatricula'] = idMatricula;
        return await api.post<TutorPractiques>("/get_tutor_matricula", data);
    }
}