import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './Modal.Component';
import {AppState} from "../../redux/store/store";
import React, {Dispatch, SetStateAction} from "react";

interface ModalProps{
    open: boolean;
    setOpen?:Dispatch<SetStateAction<boolean>>;
    closeButton?: boolean;
    onClose?: () => void;
}

const mapStateToProps = (state:AppState, props:ModalProps) => {
    return createStructuredSelector<any,any>({
    });
};

const mapDispatchToProps = {
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
