import React from "react";
import {withTranslation} from "react-i18next";

const SubHeader: React.FC = ({title,description}: any) => {

    return (
        <>
            <div className="flex flex-col px-8 py-6 mt-4 text-left bg-white text-center">
                <h3 className="text-2xl font-bold text-center">
                    {title}
                </h3>
                {   description &&
                    <p>
                        {description}
                    </p>
                }
            </div>
        </>
    )

}

export default withTranslation('common')(SubHeader);