import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './EntityForm.Component';
import {addEntity, getError} from "../../redux/reducers/entitatsReducer";
import { AppState } from 'src/redux/store/store';


const mapStateToProps = (state:AppState, props:any) => {
    return createStructuredSelector<any,any>({
        error: getError, 
    });
};


const mapDispatchToProps = {
    addEntity: addEntity,
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);