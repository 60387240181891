import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Component from './PrivateRoute.Component';
import {AppState} from "../../redux/store/store";
import {getIsInProgress, getUser} from "../../redux/reducers/userReducer";

type PrivateRouteProps = {
    path: string
    children: React.ReactNode
}

const mapStateToProps = (state:AppState, props:PrivateRouteProps) => {
    return createStructuredSelector<any,any>({
        user : getUser,
        isInProgress : getIsInProgress
    });
};

const mapDispatchToProps = {
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);