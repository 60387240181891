import { createSelector } from 'reselect'
import {AppState} from "../store/store";
import {Dispatch} from "redux";
import {DniObject, User} from "../../types/user";
import {authenticactionUser, create_new_user, newPassword, validateTokenUser} from "../../services/authoritation";

export const ACTIONS = {
    QUERY_USER_REQUEST: 'QUERY_USER_REQUEST',
    QUERY_USER_SUCCESS: 'QUERY_USER_SUCCESS',
    QUERY_CREATE_USER_SUCCESS: 'QUERY_CREATE_USER_SUCCESS',
    QUERY_USER_FAILURE: 'QUERY_USER_FAILURE',
    QUERY_NEWPASSWORD_FAILURE: 'QUERY_NEWPASSWORD_FAILURE',
    QUERY_NEWPASSWORD_SUCCESS: 'QUERY_NEWPASSWORD_SUCCESS',
    QUERY_NEWPASSWORD_REQUEST: 'QUERY_NEWPASSWORD_REQUEST',
    CLEAR_PROCESS: 'CLEAR_PROCESS',
};

const InitialState = {
    user : {} as User,
    inProgress : false,
    isCompleted: false,
    error: false,
}

const getSlice = (state: AppState) => state.user;

export const getUser = createSelector(
    getSlice,
    ({user}) => user,
);

export const getIsInProgress = createSelector(
    getSlice,
    ({inProgress}) => inProgress,
);

export const getError = createSelector(
    getSlice,
    ({error})=>error
);

export const getIsCompleted = createSelector(
    getSlice,
    ({isCompleted})=>isCompleted
);


export const setInProgress = (): UsersActions => ({
    type :ACTIONS.QUERY_USER_REQUEST,
})

export const clearProcess = (): UsersActions => ({
    type :ACTIONS.CLEAR_PROCESS,
})

export const setUsersSuccess = (user: User): UsersActions => ({
    type:ACTIONS.QUERY_USER_SUCCESS,
    user
})
export const setCreateUserSuccess = (): UsersActions => ({
    type:ACTIONS.QUERY_CREATE_USER_SUCCESS,
})

export const setUsersFailure = (error:boolean): UsersActions => ({
    type :ACTIONS.QUERY_USER_FAILURE,
    error
})

export const setNewPasswordProgress = (): UsersActions => ({
    type :ACTIONS.QUERY_NEWPASSWORD_REQUEST,
})

export const setNewPasswordSuccess = (): UsersActions => ({
    type:ACTIONS.QUERY_NEWPASSWORD_SUCCESS,
})

export const setNewPasswordFailure = (error:boolean): UsersActions => ({
    type :ACTIONS.QUERY_NEWPASSWORD_FAILURE,
    error
})


export interface UserRequestAction {
    type: typeof ACTIONS.QUERY_USER_REQUEST
}

export interface ClearAction {
    type: typeof ACTIONS.CLEAR_PROCESS
}


export interface UserSuccessAction {
    type: typeof ACTIONS.QUERY_USER_SUCCESS,
    user : User
}

export interface CreateUserSuccessAction {
    type: typeof ACTIONS.QUERY_CREATE_USER_SUCCESS,
}


export interface UserFailureAction {
    type: typeof ACTIONS.QUERY_USER_FAILURE,
    error : boolean
}

export interface NewPasswordRequestAction {
    type: typeof ACTIONS.QUERY_NEWPASSWORD_REQUEST
}

export interface NewPasswordSuccessAction {
    type: typeof ACTIONS.QUERY_NEWPASSWORD_SUCCESS,
    user : User
}

export interface NewPasswordFailureAction {
    type: typeof ACTIONS.QUERY_NEWPASSWORD_FAILURE,
    error : boolean
}

export type UsersActions =
    UserRequestAction
    | ClearAction
    | UserSuccessAction
    | UserFailureAction
    | NewPasswordRequestAction
    | NewPasswordSuccessAction
    | NewPasswordFailureAction
    | CreateUserSuccessAction
    ;

export const clearUser = () => {
    return async (dispatch: Dispatch<UsersActions>) => {
        dispatch(setInProgress())
        try {
            dispatch(setUsersSuccess({
                username: '',
                is_userapp: false,
                password: ''
            }));
        } catch (error:any) {
            dispatch(setUsersFailure(error))
        }
    }
}


export const retrieveUser = (user:User) => {
    return async (dispatch: Dispatch<UsersActions>) => {
        dispatch(setInProgress())
        try {
            const response = await authenticactionUser(user);
            dispatch(setUsersSuccess(response.data));
        } catch (error:any) {
            dispatch(setUsersFailure(error))
        }
    }
}

export const createUser = (user:DniObject) => {
    return async (dispatch: Dispatch<UsersActions>) => {
        dispatch(setInProgress())
        try {
            const response = await create_new_user(user);
            if(response.data.status === 'KO'){
                dispatch(setUsersFailure(response.data.message))
            }else {
                dispatch(setCreateUserSuccess());
            }
        } catch (error:any) {
            dispatch(setUsersFailure(error))
        }
    }
}

export const validateToken = (user:User) => {
    return async (dispatch: Dispatch<UsersActions>) => {
        dispatch(setInProgress())
        try {
            const response = await validateTokenUser(user);
            dispatch(setUsersSuccess(response.data));
        } catch (error:any) {
            dispatch(setUsersFailure(error))
        }
    }
}


export const createNewPassword = (user:User) => {
    return async (dispatch: Dispatch<UsersActions>) => {
        dispatch(setNewPasswordProgress())
        try {
            const responsePromise:any = await newPassword(user);
            const response = responsePromise.data;
            if(response.data){
                dispatch(setNewPasswordSuccess());
            } else {
                dispatch(setNewPasswordFailure(false));
            }
            // setTimeout(() => {
            //     dispatch(clearProcess());
            // }, 1000)

        } catch (error:any) {
            dispatch(setNewPasswordFailure(error))
            setTimeout(() => {
                dispatch(clearProcess());
            }, 1000)
        }
    }
}

const UserReducer = (state = InitialState, action: UsersActions):
    { inProgress: boolean; isCompleted: boolean; error: boolean; user: {} } => {
    switch (action.type) {
        case ACTIONS.QUERY_USER_REQUEST:
        case ACTIONS.QUERY_NEWPASSWORD_REQUEST:
            return {
                ...state,
                inProgress : true,
                isCompleted : false,
                error: false
            };
        case ACTIONS.QUERY_USER_SUCCESS:
            return {
                ...state,
                inProgress : false,
                user: "user" in action ? action.user : {}
            };
        case ACTIONS.QUERY_CREATE_USER_SUCCESS:
            return {
                ...state,
                inProgress : false,
                isCompleted : true,
            };
        case ACTIONS.QUERY_NEWPASSWORD_SUCCESS:
            return {
                ...state,
                inProgress : false,
                isCompleted : true,
                error: false
            };
        case ACTIONS.CLEAR_PROCESS:
            return {
                ...state,
                inProgress : false,
                isCompleted : false,
                error : false,
            };
        case ACTIONS.QUERY_USER_FAILURE:
        case ACTIONS.QUERY_NEWPASSWORD_FAILURE:
            return {
                ...state,
                inProgress : false,
                isCompleted : true,
                error: true,
            };
        default:
            return state;
    }
};



export {UserReducer};
