import                                                                                                                                                                                                                           React, {useEffect, useState} from "react";
import {Entity} from "../../types/entity";
import {withTranslation} from "react-i18next";
import Button from "../Button";
import Modal from "../Modal";
import ButtonDownloadPdf from "../ButtonDownloadPdf";

const EntityForm: React.FC = ({addEntity, error}: any) => {

    const [entity, setEntity] = useState<Entity>({
        rao_social: '',
        cif: '',
        email_responsable: '',
        responsable_legal: '',
        telephone: '',
        file: {} as File,
        file_name: ''
    })

    const [openModal, setOpenModal] = useState(false)

    const handleEntity = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        addEntity(entity);
        setOpenModal(!openModal);
    }

    const centreChange = (name: any, event: React.ChangeEvent<HTMLInputElement>): void => {
        let value = event.target.value;
        if(name === 'cif') {
            value = cleanCIF(value)
        }
        console.log(name, value);
        console.log(value);
        setEntity((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    };

    const onFileChange = (e:React.ChangeEvent<HTMLInputElement>):void  => {
        let files = e.target.files;
        let fileReader = new FileReader();
        if(files){

            setEntity((prevState) => ({
                ...prevState,
                ['file_name']: files![0].name
            }))

            fileReader.readAsDataURL(files[0]);
            fileReader.onload = (event) => {
                if(fileReader.result){
                    // @ts-ignore
                    setEntity((prevState) => ({
                        ...prevState,
                        ['file']: fileReader.result,
                    }))
                }

            }
        }
    }

    useEffect(()=>{
        console.log(error);
    }, [error])

    const cleanCIF = (cif: string): string => {
        // Remover cualquier carácter que no sea una letra o número
        return cif.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    };


    return (
        <>
            {
                openModal &&
                <>
                    <Modal open={openModal} setOpen={setOpenModal}>
                        <div>
                            <h1 className="text-3xl font-bold mb-4 text-gray-800 text-center">Afegir Centre</h1>
                            <div className="p-6 bg-gray-100 font-sans">
                                <h1 className="text-2xl font-bold mb-4 text-gray-800">Com Tramitar un Conveni de Pràctiques</h1>
                                <ul className="list-disc ml-6 space-y-4 text-gray-700">
                                    <li>
                                        Fes arribar aquest document (instruccions i conveni de pràctiques per omplir i signar) al centre de pràctiques.
                                    </li>
                                    <li>
                                        Adjuntar el conveni omplert i signat:
                                        <ul className="list-disc ml-6 space-y-2">
                                            <li>
                                                Signatura de certificat digital: penjar el document en format PDF.
                                            </li>
                                            <li>
                                                Signatura a mà (en blau): imprimir dues còpies, signar i fer arribar a l’Escola per correu postal 
                                                (AV. Drassanes 3, 08001 Barcelona). També cal penjar el document escanejat o una fotografia.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Introduir les dades sol·licitades.
                                    </li>
                                    <li>
                                        Cercar i seleccionar el centre de pràctiques en el sistema.
                                    </li>
                                </ul>
                                <p className="italic text-gray-600 mt-4">Nota: Assegureu-vos que totes les còpies siguin llegibles i compleixin amb els requisits especificats.</p>
                            </div>
                            <p>
                                <ButtonDownloadPdf
                                    title={'Conveni de pràctiques'}
                                    nameDocument={'conveni_practiques.pdf'}
                                />
                            </p>
                        </div>
                        <form
                            onSubmit={(e) => {
                                handleEntity(e)
                            }}>
                            <div>
                                <label className="block" htmlFor="email">Nom del centre</label>
                                <input type="text"
                                       required
                                       placeholder="Nom del centre"
                                       className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none
                                            focus:ring-orange-600"
                                       onChange={(e) => {
                                           centreChange('rao_social', e);
                                       }}
                                />
                            </div>
                            <div className="mt-4">
                                <label className="block">CIF/NIF del centre</label>
                                <input type="text"
                                       required
                                       placeholder="CIF"
                                       className="w-full px-4 py-2 mt-25  border rounded-md focus:outline-none
                                            focus:ring-orange-600"
                                       value={entity.cif}    
                                       maxLength={9} 
                                       onChange={(e) => {
                                           centreChange('cif', e);
                                       }}
                                />
                            </div>
                            <div className="mt-4">
                                <label className="block">Responsable legal del centre</label>
                                <input type="text"
                                       required
                                       placeholder="Responsable legal"
                                       className="w-full px-4 py-2 mt-25  border rounded-md focus:outline-none
                                            focus:ring-orange-600"
                                       onChange={(e) => {
                                           centreChange('responsable_legal', e);
                                       }}
                                />
                            </div>
                            <div className="mt-4">
                                <label className="block">Correu de la responsable legal del centre</label>
                                <input type="text"
                                       required
                                       placeholder="Correu del Responsable legal"
                                       className="w-full px-4 py-2 mt-25  border rounded-md focus:outline-none
                                            focus:ring-orange-600"
                                       onChange={(e) => {
                                           centreChange('email_responsable', e);
                                       }}
                                />
                            </div>
                            <div className="mt-4">
                                <label className="block">Telèfon del centre</label>
                                <input type="text"
                                       required
                                       placeholder="Telèfon"
                                       className="w-full px-4 py-2 mt-25  border rounded-md focus:outline-none
                                            focus:ring-orange-600"
                                       onChange={(e) => {
                                           centreChange('telephone', e);
                                       }}
                                />
                            </div>

                            <div className="mt-4">
                                <label className="block">Penja document</label>
                                <input type="file"
                                       required
                                       className="w-full px-4 py-2 mt-25  border rounded-md focus:outline-none
                                            focus:ring-orange-600"
                                       onChange={(e) => {
                                           onFileChange(e);
                                       }}
                                />
                            </div>

                            <div className="flex items-baseline justify-between">
                                <Button title="Afegir centre" onClick={e => handleEntity} />
                                <Button title="Cancel·lar"
                                        className="bg-black"
                                        onClick={e => setOpenModal(!openModal)} />
                            </div>
                        </form>
                    </Modal>
                </>

            }

            <div className="w-full flex justify-center py-12" id="button">
                <button
                    className="px-6 py-2 mt-4 text-white bg-orange-500 rounded-lg hover:bg-orange-900"
                    onClick={e => setOpenModal(!openModal)}>
                    Afegir nou centre
                </button>
            </div>

        </>
    )

}

export default withTranslation('common')(EntityForm);
